import React from 'react';
import TabBuilding from '../users/TabBuilding';
import Utils from '../common/Utils';
import CounterCard from '../common/CounterCard';

function BuildingUsageDetail() {
    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col no-margin">
                    <CounterCard title="Alerts" number="-" alertColor={true} />
                </div>
                <div className="col no-margin">
                    <CounterCard title="MicroMeters" number="-" />
                </div>
                <div className="col no-margin">
                    <CounterCard title="Gallons Used" number="-" />
                </div>
            </div>
            <div className="row">
                <div className="col no-margin">
                    <CounterCard title="Properties" number="-" />
                </div>
                <div className="col no-margin">
                    <CounterCard title="Units" number="-" />
                </div>
                <div className="col no-margin">
                    <CounterCard title="Gallons by Units" number="-" />
                </div>
            </div>
        </div>
    );
}

export default function BuildingUsage(props) {
    const newProps = Utils.cloneObject(props, 'activeIdx', 'render');
    return <TabBuilding {...newProps} activeIdx={3} render={(props) => <BuildingUsageDetail {...props} />} />;
}
