import { useState, useCallback, useEffect } from 'react';
import Utils, { useShouldUpdate } from '../common/Utils';

export default function useMgmtBuildingList(props) {
    const [buildings, setBuildings] = useState([]);
    const mId = (props && props.selCompany && props.selCompany.value) || null;

    const shouldUpdate = useShouldUpdate();

    const loadBuildings = useCallback(
        mId => {
            Utils.apiGetData(
                mId && 'table-building',
                'TbBuildingList' + mId,
                setBuildings,
                d => {
                    return Array.isArray(d) ? d.map(e => ({ label: Utils.formatAddress(e), value: e._id })) : null;
                },
                { param: { mId } },
                null,
                shouldUpdate
            );
        },
        [shouldUpdate]
    );

    useEffect(() => {
        loadBuildings(mId);
    }, [loadBuildings, mId]);

    const onMgmtCompanyChanged = useCallback(
        e => {
            if (e && e.value) loadBuildings(e.value);
            if (props && props.setSelBuilding) props.setSelBuilding(null);
            if (props && props.setSelCompany) props.setSelCompany(e);
        },
        [loadBuildings, props]
    );

    return [buildings, onMgmtCompanyChanged];
}
