import React from 'react';
import TabCompany from '../admin/TabCompany';
import Utils from '../common/Utils';

function CompanyAdminDetail() {
    return <h4>TBD</h4>;
}

export default function CompanyAdmin(props) {
    let newProps = Utils.cloneObject(props, 'activeIdx', 'render');
    return <TabCompany {...newProps} activeIdx={3} component={CompanyAdminDetail} />;
}
