import React, { useRef, useEffect, useCallback } from 'react';
import axios from 'axios';
import { AppCache } from '../common/MyCache';
import Cookies from 'js-cookie';

class Utils {
    constructor() {
        this.hostname = window && window.location && window.location.hostname;
        this.filterKeys = [];
        this.options = {
            filters: '',
            setFilterSettings: null,
        };

        this.ERR_MSG_TIMEOUT = 4000;
        this.ROLES_MANAGER = 3;
        this.OPTION_LOAD_DURATION = 1000 * 60 * 60;
    }

    isDebug() {
        return this.hostname === 'localhost';
    }

    apiBase() {
        //if (this.hostname === 'localhost') return 'http://localhost:8000/api/';
        //else return '/api/';
        return '/api/';
    }

    base() {
        //if (this.hostname === 'localhost') return 'http://localhost:8000/';
        //else return '/';
        return '/';
    }

    role() {
        const role = parseInt(Cookies.get('role'));
        return isNaN(role) ? -1 : role;
    }

    isAdminOrManager() {
        const role = this.role();
        return role >= 1 && role <= 3;
    }

    mgmtId() {
        const m = parseInt(Cookies.get('mId'));
        return isNaN(m) ? -1 : m;
    }

    apiDataCacheDuration(sec) {
        if (!sec) return 5000;
        else if (sec === 'slow') return 10000;
        else if (sec === 'fast') return 2000;
        else if (!isNaN(sec)) return sec * 1000;
        return 5000;
    }

    formatFloat(value) {
        if (typeof value === 'undefined') return '';
        return isNaN(value) || !value.toFixed ? value : value.toFixed(2);
    }

    formatAddress(loc) {
        if (!loc || !loc.street) return '';
        let rvl = loc.street;
        if (loc.city) rvl += ', ' + loc.city;
        if (loc.state) rvl += ', ' + loc.state;
        return rvl;
        //return loc.street + ', ' + loc.city + ', ' + loc.state; //  + ', ' + loc.country + ', ' + loc.postal;
    }

    formatDateTime(dateNum, na = 'N/A') {
        if (isNaN(dateNum) || dateNum < 1) return na;
        const d = new Date(dateNum);
        return d.toLocaleDateString() + ' ' + d.toLocaleTimeString();
    }

    formatDate(dateNum, na = 'N/A') {
        if (isNaN(dateNum) || dateNum < 1) return na;
        const d = new Date(dateNum);
        return d.toLocaleDateString();
    }

    formatDateInput(d) {
        if (!isNaN(d)) d = new Date(d);
        if (!d) return '';
        const offsetMs = d.getTimezoneOffset() * 60 * 1000;
        const dateLocal = new Date(d.getTime() - offsetMs);
        return dateLocal.toISOString().split('T')[0];
    }
    
    formatMonth(m) {
        const d = m && m.getFullYear && !isNaN(m.getFullYear()) ? m : isNaN(m) ? null : new Date(m);
        if (d) {
            let md = d.getMonth() + 1;
            if (isNaN(md)) return '';
            if (md < 10) md = '0' + md;
            return d.getFullYear() + '-' + md;
        } else return m;
    }

    formatAlertNum(alertNum, cardView = true) {
        if (cardView) return alertNum;
        return !isNaN(alertNum) && alertNum > 0 ? alertNum : '';
    }

    alertNumStyle(alertNum) {
        return !isNaN(alertNum) && alertNum > 0 ? { color: 'red' } : null;
    }

    formatAlertNumDiv(alertNum, cardView = true) {
        let n = NaN;
        if (typeof alertNum === 'object') {
            n = !isNaN(alertNum.oAlerts) && !isNaN(alertNum.cAlerts) ? alertNum.oAlerts + alertNum.cAlerts : alertNum.Alerts;
        } else n = alertNum;
        const rvl = this.formatAlertNum(n, cardView);
        return <div style={this.alertNumStyle(n)}>{rvl}</div>;
    }

    formatSensorSmInterval(sensor) {
        if (sensor) {
            if (sensor.useGsm) return 'Default';
            else return sensor.lsmInterval || (sensor.cfg && sensor.cfg.smInterval) || '';
        } else return '';
    }

    getSensorStatus(e) {
        let status = -1;
        if (typeof e === 'object') status = parseInt(e.status, 10);
        else if (!isNaN(e)) status = parseInt(e, 10);
        return status;
    }

    formatSensorStatus(e) {
        const redColor = { color: 'red' };
        let status = this.getSensorStatus(e);
        if (status === 1) return <div style={redColor}>{'Inactive'}</div>;
        else if (status === 0) return 'Active';
        else return 'N/A';
    }

    formatSensorLocation(e, joinStr = ', ') {
        const rvl = [];
        if (e) {
            if (e.rName) rvl.push(e.rName);
            if (e.appName) rvl.push(e.appName);
            if (e.tName) rvl.push(e.tName);
        }
        return rvl.join(joinStr);
    }

    /*
  loadOptions(fnDataLoaded) {
    if (this.options.lastLoadTime + this.OPTION_LOAD_DURATION > Date.now()) return;
    this.options.lastLoadTime = Date.now();
    axios.get(this.apiBase() + 'load-settings').then(res => {
      this.options.filters = res.data;
      this.options.lastLoadTime = Date.now();
      if (fnDataLoaded) fnDataLoaded(res.data);
    });
  }

  saveOptions(filter, fnSaved) {
    this.options.filters = filter;
    axios
      .post(this.apiBase() + 'save-settings', filter)
      .then(() => {
        this.options.lastLoadTime = Date.now();
        if (fnSaved) fnSaved();
      })
      .catch(err => {
        console.dir(err);
        //if (err && err.response && err.response.data && err.response.data.errMsg)
      });
  }

  getOptionFilter() {
    return this.options.filters;
  }

  getOptionTime() {
    return this.options.lastLoadTime;
  }
  */

    setOptionFilter(filter) {
        if (filter && typeof filter === 'object') {
            const str = JSON.stringify(filter);
            this.options.filters = str;
            if (this.options.setFilterSettings) this.options.setFilterSettings(str);
        }
    }

    getRowKey(e) {
        return e && e.target && e.target.parentElement ? parseInt(e.target.parentElement.getAttribute('data-row-idx')) : NaN;
    }

    getRowData(e, arr, arrKey = '_id') {
        const id = this.getRowKey(e);
        if (isNaN(id) || !Array.isArray(arr)) return null;
        return arr.find((e) => id === e[arrKey]);
    }

    getRowDataByValue(e, arr, arrKey = '_id') {
        const id = parseInt(e && e.target && e.target.value, 10) || NaN;
        if (isNaN(id) || !Array.isArray(arr)) return null;
        return arr.find((e) => id === e[arrKey]);
    }

    cloneObject(originObject) {
        let obj = Object.assign({}, originObject);
        for (let i = 1; i < arguments.length; i++) delete obj[arguments[i]];
        return obj;
    }

    isBadId(id) {
        return id === undefined || id === null;
    }

    prepareUrl(apiName) {
        let url = '';
        if (apiName.indexOf('/api/') !== 0 && apiName.indexOf('http:') !== 0) url += this.apiBase();

        if (apiName.charAt(0) !== '/') url += apiName;
        else url += apiName.substring(1);
        return url;
    }

    fixNumberString(obj, params = ['_id']) {
        if (!obj) return obj;

        if (Array.isArray(obj)) return obj;

        params.forEach((e) => {
            if (!isNaN(obj[e])) obj[e] = parseInt(obj[e]);
        });
        return obj;
    }

    deleteLoginCookies() {
        Cookies.remove('role');
        Cookies.remove('mId');
        Cookies.remove('email');
    }

    deleteAllCookies() {
        var cookies = document.cookie.split(';');
        for (var i = 0; i < cookies.length; i++) {
            var cookie = cookies[i];
            var eqPos = cookie.indexOf('=');
            var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
            if (name) document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT';
        }
    }

    formatError(err) {
        let code = -3;
        let errMsg = 'Internal Error';

        if (err) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            if (err.response) {
                code = err.response.status || -1;
                errMsg = (err.response.data && err.response.data.errMsg) || 'Server Error';
            }
            // The request was made but no response was received
            else if (err.request) {
                code = -2;
                errMsg = 'Network Error';
            }
        }
        if (err && typeof err === 'object') {
            err.code = code;
            err.errMsg = errMsg;
            return err;
        } else return { code, errMsg };
    }

    apiPostData(apiName, cacheKey, data, onSuccessFn, onFailFn, setLoadingFn, shouldUpdate) {
        if (!apiName) return;
        if (!shouldUpdate) shouldUpdate = { current: true };
        if (setLoadingFn) setLoadingFn(true);

        const pm = axios.post(this.prepareUrl(apiName), data);
        pm.then((res) => {
            const idList = ['_id', 'mId', 'bId', 'uId', 'tId', 'rId', 'cityId', 'stateId', 'sn', 'gId'];
            let rvl = this.fixNumberString(res.data, idList);
            if (onSuccessFn) {
                const t = onSuccessFn(rvl);
                if (t !== undefined) rvl = t;
            }
            AppCache.add(cacheKey, rvl);
            if (setLoadingFn && shouldUpdate.current) setLoadingFn(false);
        }).catch((err) => {
            const er = this.formatError(err);
            if (onFailFn) onFailFn(er, er.errMsg);
            if (setLoadingFn && shouldUpdate.current) setLoadingFn(false);
            console.log('Post error: ', err);
        });
    }

    // flush cache when filter condition changed
    clearFilterCache() {
        const keys = AppCache.keys();
        keys.forEach((k) => (this.filterKeys.indexOf(k) >= 0 ? AppCache.remove(k) : null));
    }

    // parse string of filterSettings and return true if sensor detail should be show
    showSensorDetail(filterSettings) {
        let showCol = true;
        if (filterSettings && filterSettings.length) {
            try {
                const settings = JSON.parse(filterSettings);
                if (settings.hideSensorDetail) showCol = false;
            } catch (err) {
                console.log(err);
            }
        }
        return showCol;
    }

    // add cacheKey which should be flush when filter condition changed
    addFilterKey(apiName, cacheKey) {
        if (!apiName || !cacheKey) return;
        const filterApis = ['home', 'building', 'unit', 'gallon-used', 'sensor', 'sample'];
        if (filterApis.indexOf(apiName) < 0) return;
        if (this.filterKeys.indexOf(cacheKey) >= 0) return;
        this.filterKeys.push(cacheKey);
    }

    apiGetData(apiName, cacheKey, setStateFn, preProcessDataFn, options, setLoadingFn, shouldUpdate) {
        if (!apiName || (options && options.skip)) return;
        if (!shouldUpdate) shouldUpdate = { current: true };

        if (setLoadingFn) setLoadingFn(true);

        this.addFilterKey(apiName, cacheKey);

        // try to get cache data
        const cacheData = AppCache.get(cacheKey);
        if (cacheData !== null) {
            if (setStateFn) setStateFn(cacheData);
            if (setLoadingFn) setLoadingFn(false);
            if (options.done) options.done(false, cacheData);
            return;
        }

        // no cache, get data from server
        const url = this.prepareUrl(apiName);
        const pm = axios.get(url, options && options.param ? { params: options.param } : undefined);
        pm.then((res) => {
            let rvl = this.fixNumberString(res.data);
            if (!isNaN(rvl._id)) rvl._id = parseInt(rvl._id);
            if (preProcessDataFn) {
                const t = preProcessDataFn(rvl);
                if (t !== undefined) rvl = t;
            }
            if (shouldUpdate.current) {
                if (setStateFn) setStateFn(rvl);
                if (setLoadingFn) setLoadingFn(false);
            }
            if (options.done) options.done(false, rvl);
            AppCache.add(cacheKey, rvl);
        }).catch((err) => {
            const er = this.formatError(err);
            if (options && options.fail) options.fail(er, err.errMsg);
            if (setLoadingFn && shouldUpdate.current) setLoadingFn(false);
            if (options.done) options.done('Failed to get data');
            console.log('RestApi Exception at ' + apiName + ': ' + err);
        });
    }

    logout(redirUrl = '/login', doneFn) {
        Cookies.remove('mId');
        Cookies.remove('role');
        axios
            .get(this.apiBase() + 'logout')
            .then(() => {
                if (doneFn) doneFn();
                if (redirUrl) window.location = redirUrl;
            })
            .catch((err) => {
                console.log(err);
            });
    }

    ////////////////////////////////////////////////////////////////////////////////
    // return a function to handle clicked event for checkbox click in list of checkboxes
    //
    useOnCheckItemClicked(listOfChecks, setListOfChecks, isGetByValue = null, shouldUpdate = null) {
        if (!shouldUpdate) shouldUpdate = { current: true };

        return useCallback(
            (e) => {
                if (!shouldUpdate.current) return;
                let n = null;
                if (isGetByValue === null) {
                    n = this.getRowDataByValue(e, listOfChecks);
                    if (!n) n = this.getRowData(e, listOfChecks);
                } else n = isGetByValue ? this.getRowDataByValue(e, listOfChecks) : this.getRowData(e, listOfChecks);

                // prettier-ignore
                if (n && setListOfChecks) setListOfChecks(m => m.map(s => {
            if (s._id === n._id) s.checked = !s.checked;
            return s;
      }));
            },
            [isGetByValue, listOfChecks, setListOfChecks, shouldUpdate]
        );
    }

    ////////////////////////////////////////////////////////////////////////////////
    // return a function to handle clicked event for 'check-ALL' checkbox or button
    //
    useOnCheckAllClicked(setCheckAll, setListOfChecks, shouldUpdate) {
        if (!shouldUpdate) shouldUpdate = { current: true };

        return useCallback(() => {
            if (shouldUpdate.current && setCheckAll)
                setCheckAll((m) => {
                    m = !m;
                    if (setListOfChecks)
                        setListOfChecks((s) =>
                            s.map((e) => {
                                e.checked = m;
                                return e;
                            })
                        );
                    return m;
                });
        }, [setCheckAll, setListOfChecks, shouldUpdate]);
    }

    useShouldUpdate() {
        const shouldUpdate = useRef(true);
        useEffect(() => {
            shouldUpdate.current = true;
            return () => (shouldUpdate.current = false);
        }, []);
        return shouldUpdate;
    }

    setState(props, name, e, minVal = null, maxVal = 1e12) {
        if (e) {
            let v = e.target.value;
            if (minVal !== null) {
                v = parseInt(v, 10);
                if (isNaN(v) || v < minVal) v = minVal;
                else if (v > maxVal) v = maxVal;
            }
            const s = { [name]: v };
            if (props.setState) props.setState((m) => ({ ...m, ...s }));
        }
    }
}
export default new Utils();

////////////////////////////////////////////////////////////////////////////////
// return a ref to detect if state should be ignore
export function useShouldIgnore() {
    const shouldIgnore = useRef(false);
    useEffect(() => {
        shouldIgnore.current = false;
        return () => (shouldIgnore.current = true);
    }, []);
    return shouldIgnore;
}

////////////////////////////////////////////////////////////////////////////////
// return a ref to detect if state should be update
export function useShouldUpdate() {
    const shouldUpdate = useRef(true);
    useEffect(() => {
        shouldUpdate.current = true;
        return () => (shouldUpdate.current = false);
    }, []);
    return shouldUpdate;
}
