import React from 'react';
import logo from '../img/logo-sm.png';
import DateFilterMsg from '../common/DateFilterMsg.js';

export default function FooterBottom() {
    return (
        <>
            <div className="row">
                <div className="col text-center">
                    <img src={logo} alt="DrizzleX" className="img-responsive" id="logo" />
                    <DateFilterMsg />
                </div>
            </div>
        </>
    );
}
