import React, { useState, useCallback } from 'react';
import TabCompany from '../admin/TabCompany';
import { useParams } from 'react-router-dom';
import HeaderCompany from '../common/HeaderCompany';
import useApiGetData from '../common/useApiGetData';
import WaitIconInline from '../common/WaitIconInline';
import Utils from '../common/Utils';
import Modal from '../common/Modal';
import MessageInline from '../common/MessageInline';
import DialogGrantSensor from '../common/DialogGrantSensor';
import { AppCache } from '../common/MyCache';
import MyTable from '../common/MyTable';
import '../ToggleSwitch.css';

function SensorStatusCheckbox(props) {
    const [check, setCheck] = useState(props.cell.status === 0);

    const updateCheckState = useCallback(
        (e) => {
            if (e && e.target) {
                const chkNo = e.target.checked ? 0 : 1;
                const sn = e.target.value || null;
                if (sn) {
                    setCheck(e.target.checked);
                    Utils.apiPostData('sensor-update-status', null, { status: chkNo, sn });
                    props.cell.status = chkNo;
                }
            }
        },
        [props.cell.status]
    );

    return (
        <>
            <label className="toggle_switch toggle_small">
                <input type="checkbox" defaultChecked={check} value={props.cell.sn} onChange={(e) => updateCheckState(e)} />
                <span className="toggle_slider toggle_small" />
            </label>
            <div className="toggle_text toggle_small">{check ? 'Active' : 'Deactive'}</div>
        </>
    );
}

function RevokeConfirmation(props) {
    const cnt = props.countSelected ? props.countSelected() : 0;
    const sn = cnt && cnt > 1 ? cnt + ' MicroMeters' : 'MicroMeter';
    return (
        <div>
            <div>
                You are removing selected {sn} from the management company:
                <br />
                {props.mgmtName}
            </div>
            <div>&nbsp;</div>
            <div>Are you sure?</div>
            <div>&nbsp;</div>
        </div>
    );
    /*
  <div className="form-check">
    <input type="checkbox" className="form-check-input" id="forceRevoke" />
    <label className="form-check-label" htmlFor="forceRevoke">
      Revoke sensor even if it is in used.
    </label>
  </div>
  */
}

function CompanySensorDetails() {
    const { mId } = useParams();
    const [sensors, setSensors] = useState([]);
    const CacheKey = 'CompanyDetail' + mId;
    const [loading, setLoading] = useState(true);
    const [company, setCompany] = useState({});
    const [revokeShown, toggleRevoke] = useState(false);
    const [grantShown, toggleGrant] = useState(false);
    const [msg, setMsg] = useState('');
    const [errMsg, setErrMsg] = useState(''); // for GrantSensor dialog
    const [selectedSensors, setSelectedSensors] = useState([]);
    const [selectedRemovalSensors, setSelectedRemovalSensors] = useState([]);
    const shouldUpdate = Utils.useShouldUpdate();
    const param = { param: { mId } };

    const CacheKeySensorInfo = CacheKey + 'Sensors';

    useApiGetData(mId && 'company', CacheKey, setCompany, (data) => (data.length ? data[0] : {}), param);
    useApiGetData(mId && 'sensor-info', CacheKeySensorInfo, setSensors, null, param, setLoading);

    function updateAvailSensorCache(addList, removeIds) {
        const availSensorKey = 'AvailSensors';
        let availSensors = AppCache.get(availSensorKey);
        if (availSensors && Array.isArray(availSensors)) {
            if (addList) {
                const newAddList = addList.map((e) => {
                    delete e.mId;
                    delete e.uId;
                    delete e.bId;
                    return e;
                });
                availSensors.push(newAddList);
            }
            if (removeIds) availSensors = availSensors.filter((e) => removeIds.indexOf(e.sn) < 0);
            AppCache.set(availSensorKey, availSensors);
        }
    }

    // add sensor to the management company
    const grantSensor = useCallback(() => {
        if (selectedSensors.length) {
            const ids = selectedSensors.map((e) => e.sn);
            Utils.apiPostData(
                'sensor-addMgmt',
                CacheKeySensorInfo,
                { mId, ids },
                () => {
                    const newSensors = selectedSensors.map((e) => {
                        e.mId = mId;
                        return e;
                    });
                    const newList = [...sensors, ...newSensors];
                    if (shouldUpdate.current) {
                        setSensors(newList);
                        updateAvailSensorCache(null, ids);
                        setSelectedSensors([]);
                        setErrMsg('');
                        toggleGrant(false);
                    }
                    return newList;
                },
                (e, eMsg) => {
                    if (shouldUpdate.current) setErrMsg(eMsg || 'Add failed');
                }
            );
        } else setErrMsg('Please select at least 1 sensor');
        return false;
    }, [CacheKeySensorInfo, mId, selectedSensors, sensors, shouldUpdate]);

    const selOneMsg = 'Please select at least 1 MicroMeter';

    const revokeSensor = useCallback(() => {
        if (selectedRemovalSensors.length) {
            const ids = selectedRemovalSensors.map((e) => e.sn);
            Utils.apiPostData(
                'sensor-removeMgmt',
                CacheKeySensorInfo,
                { mId, ids },
                () => {
                    const newList = sensors.filter((e) => ids.indexOf(e.sn) < 0);
                    if (shouldUpdate.current) {
                        setSensors(newList);
                        updateAvailSensorCache(selectedRemovalSensors);
                        setSelectedRemovalSensors([]);
                        setMsg('');
                    }
                    return newList;
                },
                (e, eMsg) => {
                    if (shouldUpdate.current) setMsg(eMsg || 'Removal failed');
                }
            );
        } else setMsg(selOneMsg);
        return true;
    }, [CacheKeySensorInfo, mId, selectedRemovalSensors, sensors, shouldUpdate]);

    const showRevokeSensorDlg = useCallback(() => {
        const cnt = selectedRemovalSensors.length;
        if (cnt) toggleRevoke(true);
        else setMsg(selOneMsg);
    }, [selectedRemovalSensors.length]);

    const menuItems = [
        { title: 'Add MicroMeters', onClicked: () => toggleGrant(true) },
        { title: 'Remove MicroMeters', onClicked: showRevokeSensorDlg },
    ];

    const tableColumns = [
        { Header: 'SN', accessor: 'sn' },
        {
            Header: 'Status',
            accessor: 'status',
            // eslint-disable-next-line react/display-name
            Cell: (props) => {
                return <SensorStatusCheckbox cell={props.cell.row.original} />;
            },
        },
        {
            Header: 'Installed',
            accessor: 'setupDate',
            Cell: (props) => Utils.formatDate(props.cell.value),
        },
        { Header: 'Battery', accessor: 'battery', Cell: (props) => Math.floor(props.cell.value + 0.5) + '%' },
    ];

    //{ Header: 'Using', accessor: 'uId', Cell: props => (isNaN(props.cell.value) ? 'No' : 'Yes') },

    return loading ? (
        <WaitIconInline />
    ) : (
        <>
            <div className="container-fluid">
                <HeaderCompany name={company.name} address={Utils.formatAddress(company)} leftMenuItem={menuItems} />
                <MessageInline msg={msg} setMsg={setMsg} />
                <div className="table-responsive-sm">
                    <MyTable columns={tableColumns} data={sensors} defaultPageSize={10} setSelectedRows={setSelectedRemovalSensors} />
                </div>
            </div>
            <Modal title="Remove MicroMeters" show={revokeShown} toggle={toggleRevoke} onOk={revokeSensor} lbCancel={'Cancel'} lbOk={' Yes '}>
                <RevokeConfirmation mgmtName={company.name} countSelected={() => selectedRemovalSensors.length} />
            </Modal>
            <Modal title="Add MicroMeters" show={grantShown} toggle={toggleGrant} onOk={grantSensor} lbCancel={'Cancel'} lbOk={'Submit'}>
                <DialogGrantSensor msg={errMsg} setMsg={setErrMsg} setSelectedRows={setSelectedSensors} />
            </Modal>
        </>
    );
}

export default function CompanySensor(props) {
    let newProps = Utils.cloneObject(props, 'activeIdx', 'render');
    return <TabCompany {...newProps} activeIdx={1} render={(props) => <CompanySensorDetails {...props} />} />;
}
