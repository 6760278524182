import React from 'react';
import Tab from '../common/Tab';

const menuItems = {
    menuMain: [
        { title: 'Home', url: '/' },
        { title: 'Alert', url: '/alert', alertCnt: true },
        { title: 'Properties', url: '/building' },
        { title: 'Person', url: '/person' },
    ],
    menuBuilding: [
        { title: 'View', url: '/building-view', id: true },
        { title: 'Units', url: '/building-unit', id: true },
        { title: 'Alerts', url: '/building-alert', id: true },
        { title: 'Usage', url: '/building-usage', id: true },
        { title: 'Public Areas', url: '/building-public-area', id: true },
        { title: 'City Bills', url: '/building-bill', id: true },
        { title: 'Reports', url: '/building-report', id: true },
    ],
    menuUnit: [
        { title: 'View', url: '/building-unit-view', id: true },
        { title: 'MicroMeters', url: '/building-unit-sensor', id: true },
        { title: 'Detail Usage', url: '/building-unit-usage', id: true },
    ],
    menuAdmin: [
        { title: 'Home', url: '/admin' },
        { title: 'Company', url: '/admin/company' },
        { title: 'Gateway', url: '/admin/gateway' },
        { title: 'New MicroMeters', url: '/admin/new-sensor' },
        { title: 'Download', url: '/admin/download' },
        { title: 'Setup', url: '/admin/setup' },
    ],
    menuCompany: [
        { title: 'View', url: '/admin/company-view', id: true },
        { title: 'MicroMeters', url: '/admin/company-sensor', id: true },
        { title: 'Threshold', url: '/admin/company-threshold', id: true },
        { title: 'Admin', url: '/admin/company-admin', id: true },
    ],
};

export default function Header(props) {
    const menuName = props.menu || 'menuMain';
    const menu = menuItems[menuName] || menuItems.mainMenu;
    const noBorder = props.noBorder || props.nav;
    const activeIdx = props.activeIdx || 0;
    const navType = props.nav || null;
    const Wrapper = props.component || null;

    return (
        <>
            <Tab items={menu} activeIdx={activeIdx} nav={navType} alertCnt={props.alertCnt} />
            {props.render || Wrapper ? (
                <div className="tab-content">
                    <div role="tabpanel" aria-hidden="false" className="fade tab-pane active show">
                        <div className={noBorder ? null : 'tab-body'}>{Wrapper ? <Wrapper /> : props.render(props)}</div>
                    </div>
                </div>
            ) : null}
        </>
    );
}
