import React, { useState, useCallback } from 'react';
import HeaderTopAdmin from '../admin/HeaderTopAdmin';
import Header from '../common/Header';
import useApiGetData from '../common/useApiGetData';
import WaitIconInline from '../common/WaitIconInline';
import Utils from '../common/Utils';

function NewSensorDetail() {
    const [loading, setLoading] = useState(true);
    const [state, setState] = useState({ sensors: [] });
    const cacheKey = 'AdminNewSensorDataList';

    useApiGetData('sample-new', cacheKey, setState, (data) => ({ sensors: data }), null, setLoading);

    const getBatteryStr = useCallback(e => {
        return Math.floor(e.battery + 0.5) + '%';
    }, []);

    return loading ? (
        <WaitIconInline />
    ) : (
        <div className="container-fluid table-margin">
            <div className="table-responsive-sm">
                <table className="tab-table table table-sm table-striped table-bordered table-hover">
                    <thead className="thead-dark">
                        <tr>
                            <th scope="col">Timestamp</th>
                            <th scope="col">MicroMeter SN</th>
                            <th scope="col">Gallons</th>
                            <th scope="col">Battery</th>
                        </tr>
                    </thead>
                    <tbody>
                        {state.sensors.map((e, idx) => (
                            <tr key={idx + '_' + e._id}>
                                <td>{Utils.formatDateTime(e.ct)}</td>
                                <td>{e.sn}</td>
                                <td>{Utils.formatFloat(e.dt)}</td>
                                <td>{getBatteryStr(e)}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default function NewSensor() {
    return (
        <>
            <HeaderTopAdmin />
            <Header menu={'menuAdmin'} activeIdx={3} render={(props) => <NewSensorDetail {...props} />} />
        </>
    );
}
