import React, { useState } from 'react';
import TabCompany from '../admin/TabCompany';
import CounterCard from '../common/CounterCard';
import { useParams } from 'react-router-dom';
import HeaderCompany from '../common/HeaderCompany';
import useApiGetData from '../common/useApiGetData';
import WaitIconInline from '../common/WaitIconInline';
import Utils from '../common/Utils';

function CompanyViewDetail() {
    const { mId } = useParams();
    const [company, setCompany] = useState({});
    const [sensors, setSensors] = useState([]);
    const CacheKey = 'CompanyDetail' + mId;
    const [loading, setLoading] = useState(true);

    const param = { param: { mId } };
    useApiGetData(mId && 'company', CacheKey, setCompany, (data) => data[0], param, setLoading);
    useApiGetData(mId && 'sensor-info', CacheKey + 'Sensors', setSensors, null, param);

    const bdCnt = (Array.isArray(company.bIds) && company.bIds.length) || 0;

    return loading ? (
        <WaitIconInline />
    ) : (
        <div className="container-fluid">
            <HeaderCompany name={company.name} address={Utils.formatAddress(company)} />
            <div className="row">
                <div className="col no-margin">
                    <CounterCard title="Properties" number={bdCnt} />
                </div>
                <div className="col no-margin">
                    <CounterCard title="MicroMeters" number={sensors.length} />
                </div>
            </div>
        </div>
    );
}

export default function CompanyView(props) {
    let newProps = Utils.cloneObject(props, 'activeIdx', 'render');
    return <TabCompany {...newProps} activeIdx={0} render={(props) => <CompanyViewDetail {...props} />} />;
}
