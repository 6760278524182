import React, { useState, useCallback } from 'react';
import { Redirect, useParams } from 'react-router-dom';
import TabBuilding from '../users/TabBuilding';
import Utils, { useShouldUpdate } from '../common/Utils';
import useGetBuildingById, { defaultBuilding } from '../common/GetBuildingById';
import useApiGetData from '../common/useApiGetData';
import { addUnitToCache } from '../common/GetUnitById';
import HeaderBuilding from '../common/HeaderBuilding';
import HeaderSearch from '../common/HeaderSearch';
import Modal from '../common/Modal';
import WaitIconInline from '../common/WaitIconInline';
import DialogAddUnit from '../common/DialogAddUnit';

const menu = [{ title: 'Alert' }, { title: 'Unit Number' }];

function UnitDetail() {
    const [loading, setLoading] = useState(true);
    const [modalShown, toggleModal] = useState(false);
    const [bd, setBuilding] = useState(defaultBuilding);
    const [state, setState] = useState({ units: [] });
    const [clickedUnitId, setClickedUnitId] = useState(NaN);
    const { bId } = useParams();
    const badId = Utils.isBadId(bId);
    const cacheKey = 'UnitDetailList' + bId;
    const defaultUnit = { name: '', place: 1, bId: parseInt(bId), area: 1.0, tenant: '', tenantCnt: 0, bathroomCnt: 0 };

    const [unit, setUnit] = useState(defaultUnit);
    const [errMsg, setErrMsg] = useState('');

    const shouldUpdate = useShouldUpdate();

    useGetBuildingById(bId, setBuilding, badId);
    useApiGetData(
        'unit',
        cacheKey,
        setState,
        (data) => {
            return { units: Array.isArray(data) ? data : [] };
        },
        { param: { bId }, badId },
        setLoading
    );

    const addUnit = useCallback(() => {
        const tenantCnt = parseInt(unit.tenantCnt);
        const bathroomCnt = parseInt(unit.bathroomCnt);
        let errText = null;
        if (badId) errText = 'Fail to get building information.';
        else if (!unit.name) errText = 'Please enter the unit number.';
        else if (!unit.tenant) errText = 'Please enter the name of tenant.';
        else if (isNaN(tenantCnt)) errText = 'Invalid # of tenants.';
        else if (isNaN(bathroomCnt)) errText = 'Invalid # of bathrooms.';

        if (errText) {
            setErrMsg(errText);
            return false;
        }

        let submitData = { ...unit };
        submitData.bId = parseInt(bId);

        // prettier-ignore
        Utils.apiPostData('add-unit', cacheKey, submitData, data => {
        const newList = { units: state.units.concat([data]) };
        if (shouldUpdate.current) {
          if (data && !isNaN(data._id)) setBuilding(b => {
            const id = parseInt(data._id, 10);
            if (b && Array.isArray(b.units) && b.units.indexOf(id) < 0) b.units.push(id);
            return b;
          });
          setState(newList);
          setErrMsg('');
          if (modalShown) toggleModal(false);
        }
        return newList;
      },
      (err, em) => {
        if (shouldUpdate.current) setErrMsg(em || 'Error while saving data');
      }
    );

        return false;
    }, [bId, unit, setErrMsg, badId, cacheKey, modalShown, shouldUpdate, state]);

    const onRowClicked = useCallback(
        (e) => {
            const item = Utils.getRowData(e, state.units);
            if (item && !Utils.isBadId(bId)) {
                addUnitToCache(item);
                setClickedUnitId(item._id);
            }
        },
        [bId, state]
    );

    if (badId) return <Redirect to={'/building'} />;
    if (!isNaN(clickedUnitId)) return <Redirect to={'/building-unit-view/' + bId + '/' + clickedUnitId} />;

    const addUnitLabel = Utils.isAdminOrManager() ? 'Add Unit' : null;

    return loading ? (
        <WaitIconInline />
    ) : (
        <>
            <div className="container-fluid table-margin">
                <HeaderSearch leftTitle={addUnitLabel} menuItems={menu} leftBtnClick={toggleModal} />
                <HeaderBuilding address={Utils.formatAddress(bd)} manager={bd.managerName} pic={bd.pic} />

                <div className="table-responsive-sm">
                    <table className="tab-table table table-sm table-striped table-bordered table-hover">
                        <thead className="thead-dark">
                            <tr>
                                <th scope="col">Unit</th>
                                <th scope="col">MicroMeters</th>
                                <th scope="col">Alerts</th>
                                <th scope="col">Total water</th>
                                <th scope="col"># of tenants</th>
                                <th scope="col">Gallon per person</th>
                            </tr>
                        </thead>
                        <tbody>
                            {state.units.map((e) => (
                                <tr key={e._id} onClick={onRowClicked} data-row-idx={e._id} className="clickable-row">
                                    <td>{e.name}</td>
                                    <td>{e.sensors.length}</td>
                                    <td style={Utils.alertNumStyle(e.cAlerts + e.oAlerts)}>{Utils.formatAlertNum(e.cAlerts + e.oAlerts, false)}</td>
                                    <td>{Utils.formatFloat(e.gallons)}</td>
                                    <td>{e.tenantCnt}</td>
                                    <td>{e.tenantCnt ? Utils.formatFloat(e.gallons / e.tenantCnt) : 'N/A'}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
            <Modal title="Add Unit" show={modalShown} toggle={toggleModal} onOk={addUnit} lbCancel={'Cancel'} lbOk={'Submit'}>
                <DialogAddUnit unit={unit} building={bd} setState={setUnit} msg={errMsg} setMsg={setErrMsg} />
            </Modal>
        </>
    );
}

export default function BuildingUnit(props) {
    const newProps = Utils.cloneObject(props, 'activeIdx', 'render');
    return <TabBuilding {...newProps} activeIdx={1} render={(props) => <UnitDetail {...props} />} />;
}
