import React, { useState } from 'react';
import TabBuilding from '../users/TabBuilding';
import Utils from '../common/Utils';
import HeaderBuilding from '../common/HeaderBuilding';
import AlertList from '../common/AlertList';
import useGetBuildingById, { defaultBuilding } from '../common/GetBuildingById';
import { Redirect, useParams } from 'react-router-dom';
import CounterCard from '../common/CounterCard';

export default function BuildingAlert(props) {
    const newProps = Utils.cloneObject(props, 'activeIdx', 'render');
    const [bd, setBuilding] = useState(defaultBuilding);
    const { bId } = useParams();
    const badId = Utils.isBadId(bId);

    useGetBuildingById(bId, setBuilding, badId);

    if (badId) return <Redirect to={'/building'} />;

    return (
        <>
            <TabBuilding
                {...newProps}
                activeIdx={2}
                render={(props) => (
                    <>
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col no-margin">
                                    <CounterCard title="Alerts Closed" number="-" alertColor={true} />
                                </div>
                                <div className="col no-margin">
                                    <CounterCard title="# days to close" number="-" />
                                </div>
                                <div className="col no-margin">
                                    <CounterCard title="Most disorder" number="-" />
                                </div>
                            </div>

                            <HeaderBuilding address={Utils.formatAddress(bd)} manager={bd.managerName} pic={bd.pic} />
                            <div className="table-responsive-sm">
                                <AlertList {...props} bId={bId} skip={badId} />
                            </div>
                        </div>
                    </>
                )}
            />
        </>
    );
}
