import md5 from 'md5';
import axios from 'axios';
import Cookies from 'js-cookie';
import Utils from '../common/Utils';

function isLoginSetRole(data) {
  const role = (data && data.result && !isNaN(data.mId) && !isNaN(data.role) && parseInt(data.role, 10)) || NaN;
  if (!isNaN(role) && role > 0) {
    Cookies.set('role', role);
    Cookies.set('mId', parseInt(data.mId, 10));
    Cookies.set('email', data.email || '');
    return role;
  }
  return 0;
}

export default async function AuthUser(userId = '', password = '', requiredLocalAdmin = false) {
  Utils.deleteLoginCookies();
  let challenge = '';

  // try to get challenge from cache
  if (userId && password) {
    challenge = Cookies.get('challenge');
    if (challenge) {
      const exp = parseInt(Cookies.get('challengeTtl'));
      if (isNaN(exp) || exp < Date.now()) challenge = null;
    }
  }

  Cookies.remove('challenge');
  Cookies.remove('challengeTtl');

  // if cache is not available, get from api
  if (!challenge) {
    const res = await axios.post(Utils.apiBase() + 'login', { pwd: '', requiredLocalAdmin });
    if (res && res.data) {
      const data = res.data;
      const role = isLoginSetRole(res.data);
      if (role) return role;
      
      challenge = data.challenge;
      if (!challenge) {
        Utils.deleteLoginCookies();
        throw new Error('Login failed');
      }
    }
  }

  // try to authenticate
  const hash = md5(challenge + md5(password) + userId);

  const r = await axios.post(Utils.apiBase() + 'login', { email: userId, pwd: hash, requiredLocalAdmin });
  if (r && r.data) {
    const d = r.data;

    // Login OK
    const role = isLoginSetRole(d);
    if (role) return role;

    // Login fail. Keep the new challenge
    if (d.challenge) {
      Cookies.set('challenge', d.challenge);
      Cookies.set('challengeTtl', Date.now() + 60000 * 10);
    }
  }
  
  Utils.deleteLoginCookies();
  throw new Error('Fail to login');
}
