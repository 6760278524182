import React, { useState, useCallback, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import Logo from '../img/logo.png';
import Waiting from '../img/waiting.svg';
import AuthUser from '../common/AuthUser';
import MessageInline from '../common/MessageInline';
import { useShouldUpdate } from '../common/Utils';
import Cookies from 'js-cookie';

export default function Login() {
    const [userId, setUserId] = useState('');
    const [pwd, setPwd] = useState('');
    const [login, setLogin] = useState(0);
    const [waitIcon, showWait] = useState(null);
    const [msg, setMsg] = useState('');
    const shouldUpdate = useShouldUpdate();
    const redirUrl = Cookies.get('redir');
    const requiredLocalAdmin = redirUrl ? true : false;

    useEffect(() => {
        let update = true;

        AuthUser('', '', requiredLocalAdmin)
            .then((rsl) => {
                if (update) {
                    showWait(null);
                    setMsg('');
                    setLogin(rsl);
                }
            })
            .catch(() => null);
        //.catch(err => console.log(err));
        return () => (update = false);
    }, [requiredLocalAdmin]);

    const onLogin = useCallback(
        (e) => {
            if (e) e.preventDefault();

            const defaultWaitIcon = <img src={Waiting} alt="Wait..." />;
            showWait(defaultWaitIcon);

            AuthUser(userId, pwd, requiredLocalAdmin)
                .then((rsl) => {
                    if (shouldUpdate.current) {
                        showWait(null);
                        setMsg('');
                        setLogin(rsl);
                    }
                })
                .catch((err) => {
                    if (shouldUpdate.current) {
                        setLogin(-1);
                        showWait(null);
                        if (requiredLocalAdmin) setMsg('Please login as local admin/manager');
                        else setMsg('Login failed! Please try again.');
                    }
                    console.log('Failed: ' + err);
                });
        },
        [userId, pwd, requiredLocalAdmin, shouldUpdate]
    );

    if (login < 1) {
        return (
            <div className="container-fluid">
                <div className="login-form">
                    <div className="text-center logo-img">
                        <img src={Logo} alt="DrizzleX" />
                    </div>
                    <form id="loginForm" method="post" onSubmit={onLogin}>
                        <div className="form-group">
                            <label htmlFor="email">Email address</label>
                            <input
                                type="email"
                                className="form-control"
                                id="email"
                                name="email"
                                aria-describedby="emailHelp"
                                placeholder="Enter email"
                                maxLength="48"
                                minLength="3"
                                autoFocus={true}
                                required
                                value={userId}
                                autoComplete="username"
                                onChange={(e) => setUserId(e.target.value)}
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="pwd">Password</label>
                            <input
                                type="password"
                                className="form-control"
                                id="pwd"
                                name="pwd"
                                placeholder="Enter password"
                                maxLength="32"
                                minLength="8"
                                required
                                value={pwd}
                                autoComplete="current-password"
                                onChange={(e) => setPwd(e.target.value)}
                            />
                        </div>
                        <div className="form-group">
                            <button type="submit" className="btn btn-primary">
                                &nbsp; Login &nbsp;
                            </button>
                        </div>
                        <div>{waitIcon}</div>
                        <div>
                            <MessageInline msg={msg} setMsg={setMsg} />
                        </div>
                        <div>
                            Don&apos;t have account? <a href="#signup">Sign up here</a>
                        </div>
                        <div>
                            Forget your password? <a href="#recover">Recover it here</a>
                        </div>
                    </form>
                </div>
            </div>
        );
    }

    if (redirUrl) {
        Cookies.remove('redir');
        return <Redirect to={redirUrl} />;
    }

    return login !== 1 ? <Redirect to="/" /> : <Redirect to="/admin" />;
}
