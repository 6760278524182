import React, { useState, useCallback, useEffect } from 'react';
import Utils from '../common/Utils';
import Select from 'react-select';
import useApiGetData from '../common/useApiGetData';
import { Redirect } from 'react-router-dom';
import MessageInline from '../common/MessageInline';
import Cookies from 'js-cookie';
import Logo from '../img/logo.png';
import WaitIconInline from '../common/WaitIconInline';
import { AppCache } from './MyCache';

function getSensorId() {
    const url = window.location.href;
    let idx = url.indexOf('sn=');
    if (idx < 0) {
        idx = url.indexOf('/as/');
        if (idx > 0) idx += 4;
    } else idx += 3;
    return idx > 0 ? parseInt(url.substring(idx)) : NaN;
}

function DialogInstallSensor() {
    const shouldUpdate = Utils.useShouldUpdate();
    const [msg, setMsg] = useState({ title: '' });
    const [buildings, setBuildings] = useState([]);
    const [units, setUnits] = useState([]);
    const [rooms, setRooms] = useState([]);
    const [waterTypes, setWaterTypes] = useState([]);
    const [appliances, setApplicances] = useState([]);
    const mId = Utils.mgmtId();
    const [loading, setLoading] = useState(true);
    const sensorId = getSensorId();
    const [sensor, setSensor] = useState(null);

    // function loadOption(name) {
    //   if (!mId) return null;
    //   const value = Cookies.get(name + mId + '_v');
    //   if (value && !isNaN(value)) {
    //     const label = Cookies.get(name + mId + '_l') || '';
    //     return { value, label };
    //   }
    //   return null;
    // }
    //const [building, setBuilding] = useState(() => loadOption('building'));
    //const [unit, setUnit] = useState(() => loadOption('unit'));

    const [building, setBuilding] = useState(null);
    const [unit, setUnit] = useState(null);

    const [room, setRoom] = useState(null);
    const [waterType, setWaterType] = useState(null);
    const [appliance, setApplicance] = useState(null);

    const mapValueFn = useCallback((d) => d.map((e) => ({ value: e._id, label: e.street || e.name })), []);

    const setInlineMsg = useCallback((msg, info) => {
        info = info ? true : false;
        if (msg && typeof msg === 'object') setMsg({ title: msg.title, info });
        else setMsg({ title: msg || '', info });
    }, []);

    const onSave = useCallback(() => {
        let err = '';
        if (isNaN(sensorId)) err = 'SN of MicroMeter is invalid';
        else if (!building) err = 'Please select building';
        else if (!unit) err = 'Please select unit';
        else if (!room) err = 'Please select room';
        else if (!waterType) err = 'Please select water type';
        else if (!appliance) err = 'Please select appliance';

        if (err) {
            setInlineMsg(err);
            return false;
        }

        // function saveOption(name, o) {
        //   if (mId && o && o.value) {
        //     Cookies.set(name + mId + '_v', o.value, { expires: 1 });
        //     Cookies.set(name + mId + '_l', o.label || '', { expires: 1 });
        //   }
        // }
        // saveOption('building', building);
        // saveOption('unit', unit);

        const ss = {
            mId,
            sn: sensorId,
            bId: building.value,
            uId: unit.value,
            rId: room.value,
            rName: room.label,
            tId: waterType.value,
            tName: waterType.label,
            appId: appliance.value,
            appName: appliance.label,
        };

        const notFound = 'MicroMeter not found or not assigned';
        Utils.apiPostData(
            'install-sensor',
            '',
            ss,
            () => (shouldUpdate.current ? setInlineMsg('Data saved sucessfully', true) : null),
            (err) => (shouldUpdate.current ? setInlineMsg(err && err.code && err.code === 404 ? notFound : 'Error while saving data') : null)
        );

        return true;
    }, [appliance, building, mId, room, sensorId, setInlineMsg, shouldUpdate, unit, waterType]);

    useApiGetData(mId && 'table-building', 'TbBuildingList' + mId, setBuildings, mapValueFn, null, setLoading);

    // load list of unit if building is in cookie
    const bId = (building && building.value) || 0;
    useApiGetData(bId && 'table-unit', 'TbUnitList' + bId, setUnits, mapValueFn, { param: { bId } });

    useApiGetData('table-room', 'TbRoomList', setRooms, mapValueFn);
    useApiGetData('table-watertype', 'TbWaterTypeList', setWaterTypes, mapValueFn);
    useApiGetData('table-appliance', 'TbApplianceList', setApplicances, mapValueFn);

    const sensorParams = { param: { sn: sensorId } };
    useApiGetData(
        sensorId && 'sensor-info-detail',
        'SensorInfoDetail' + sensorId,
        (data) => {
            // set state if sensor is already configured
            if (data) {
                setSensor(data);
                if (data.building) setBuilding({ label: data.building.street, value: data.building._id });
                if (Array.isArray(data.units)) {
                    const bId = (data.building && data.building._id) || 0;
                    AppCache.set('TbUnitList' + bId, data.units);
                    setUnits(data.units);
                }
                if (data.unit) setUnit({ label: data.unit.name, value: data.unit._id });
                if (!isNaN(data.rId) && data.rName) setRoom({ label: data.rName, value: data.rId });
                if (!isNaN(data.tId) && data.tName) setWaterType({ label: data.tName, value: data.tId });
                if (!isNaN(data.appId) && data.appName) setApplicance({ label: data.appName, value: data.appId });
            }
        },
        null,
        sensorParams
    );

    useEffect(() => {
        let ignore = false;

        function setSelect(setState, id, list) {
            if (setState && !isNaN(id) && list && list.length) {
                id = parseInt(id, 10);
                const val = list.find((e) => e.value === id);
                if (!ignore && val) setState(val);
            }
        }

        if (sensor) {
            setSelect(setBuilding, sensor.bId, buildings);
            setSelect(setUnit, sensor.uId, units);
            setSelect(setRoom, sensor.rId, rooms);
            setSelect(setWaterType, sensor.tId, waterTypes);
            setSelect(setApplicance, sensor.appId, appliances);
        }
        return () => (ignore = true);
    }, [appliances, buildings, rooms, sensor, units, waterTypes]);

    function onBuidingChange(e) {
        setBuilding((m) => {
            if (m !== e) {
                const bId = e && e.value;
                Utils.apiGetData(bId && 'table-unit', 'TbUnitList' + bId, setUnits, mapValueFn, { param: { bId } }, null, shouldUpdate);
                setUnit(null);
            }
            return e;
        });
    }

    function phText(name) {
        return 'Select ' + name + ' ...';
    }

    const textProps = { maxLength: 64, type: 'text', className: 'form-control' };
    const selPros = { classNamePrefix: 'react-select-focus', isClearable: true };
    const sensorIdStyle = sensor && sensor.uId ? { color: '#1E8449' } : null;
    return (
        <div className="container-fluid">
            <div className="install-sensor-form">
                <div className="text-center logo-img">
                    <img src={Logo} alt="DrizzleX" />
                    <WaitIconInline hide={!loading} />
                </div>
                <form method="post">
                    <div className="form-group row">
                        <div className="col-sm-12">
                            <input style={sensorIdStyle} {...textProps} id="fieldUnitName" value={'MicroMeter SN: ' + sensorId} readOnly disabled />
                        </div>
                    </div>
                    <div className="form-group row">
                        <div className="col-sm-12">
                            <Select placeholder={phText('Building')} {...selPros} options={buildings} onChange={onBuidingChange} value={building} />
                        </div>
                    </div>
                    <div className="form-group row">
                        <div className="col-sm-12">
                            <Select placeholder={phText('Unit')} {...selPros} options={units} onChange={setUnit} value={unit} />
                        </div>
                    </div>
                    <div className="form-group row">
                        <div className="col-sm-12">
                            <Select placeholder={phText('Room')} {...selPros} options={rooms} onChange={setRoom} value={room} />
                        </div>
                    </div>
                    <div className="form-group row">
                        <div className="col-sm-12">
                            <Select placeholder={phText('Water type')} {...selPros} options={waterTypes} onChange={setWaterType} value={waterType} />
                        </div>
                    </div>
                    <div className="form-group row">
                        <div className="col-sm-12">
                            <Select placeholder={phText('Appliance')} {...selPros} options={appliances} onChange={setApplicance} value={appliance} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col text-center">
                            <button type="button" className="btn btn-info btn-width-normal" onClick={onSave}>
                                {'Save'}
                            </button>
                        </div>
                    </div>
                </form>
                <MessageInline msg={msg.title} setMsg={setInlineMsg} info={msg.info} />
            </div>
        </div>
    );
}

export default function InstallSensor(props) {
    const role = Utils.role();
    const mId = Utils.mgmtId();

    if ((role !== 2 && role !== 3) || !mId) {
        Cookies.set('redir', props.location.pathname + props.location.search);
        if (role > 0) Utils.logout(null);
        else return <Redirect to="/login" />;
    }

    return <DialogInstallSensor />;
}
