import React, { useContext } from 'react';
import { ContextFilterSettings } from '../common/ContextFilterSettings';

export default function DateFilterMsg() {
    function getDateRange(settings) {
        if (!settings || !settings.length) return null;
        let obj = null;
        try {
            obj = JSON.parse(settings);
        } catch (err) {
            console.log(err);
        }
        if (!obj) return null;
        let fromDate = new Date(obj.filterFirstDay) || null;
        let toDate = new Date(obj.filterLastDay) || null;
        if (obj.filterType === 1) {
            toDate = new Date();
            fromDate = new Date(toDate.getTime() - 24 * 60 * 60 * 1000 * obj.numDays);
        } else if (obj.filterType === 2) {
            const d = new Date(obj.filterFirstDay);
            fromDate = new Date(d.getFullYear(), d.getMonth(), 1);
            toDate = new Date(d.getFullYear(), d.getMonth() + 1, 0);
        }
        return 'Date filtered: ' + fromDate.toLocaleDateString() + ' - ' + toDate.toLocaleDateString();
    }

    const settings = useContext(ContextFilterSettings);
    const dateRange = getDateRange(settings);
    if (!dateRange) return null;
    return (
        <div>
            <strong>{dateRange}</strong>
        </div>
    );
}
