import React from 'react';
export default function PlaceHolder(props) {
    return (
        <div>
            <h4>Tab data here</h4>
            <div>Just place holder</div>
            <div>&nbsp;</div>
            <div>&nbsp;</div>
            <div>&nbsp;</div>
            <div>&nbsp;</div>
            <div>&nbsp;</div>
            <div>&nbsp;</div>
            <div>&nbsp;</div>
        </div>
    );
}
