import React, { useEffect } from 'react';
import { useTable, usePagination, useRowSelect } from 'react-table';

// eslint-disable-next-line react/display-name
const IndeterminateCheckbox = React.forwardRef(({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
        resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
        <div style={{ textAlign: 'center' }}>
            <input type="checkbox" ref={resolvedRef} {...rest} />
        </div>
    );
});

const defaultPropGetter = () => ({});

export default function MyTable({
    columns,
    data,
    defaultPageSize = null,
    setSelectedRows = null,
    hideGoToPage = false,
    rowClassName = null,
    onRowClicked = null,
}) {
    if (!defaultPageSize || isNaN(defaultPageSize) || defaultPageSize < 1) defaultPageSize = 9999;

    const {
        getTableProps,
        getTableBodyProps,
        headers,
        prepareRow,
        page, // Instead of using 'rows', we'll use page, which has only the rows for the active page
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        getHeaderProps = defaultPropGetter,
        getColumnProps = defaultPropGetter,
        getCellProps = defaultPropGetter,
        selectedFlatRows,
        state: { pageIndex, selectedRowIds },
    } = useTable(
        {
            columns,
            data,
            initialState: { pageSize: defaultPageSize, pageIndex: 0 },
        },
        useRowSelect,
        usePagination,
        (hooks) => {
            if (setSelectedRows)
                hooks.flatColumns.push((columns) => [
                    // Let's make a column for selection
                    {
                        id: 'selection',
                        // The header can use the table's getToggleAllRowsSelectedProps method
                        // to render a checkbox

                        // eslint-disable-next-line react/display-name
                        Header: ({ getToggleAllRowsSelectedProps }) => <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />,

                        // The cell can use the individual row's getToggleRowSelectedProps method
                        // to the render a checkbox
                        // eslint-disable-next-line react/display-name
                        Cell: ({ row }) => <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />,
                    },
                    ...columns,
                ]);
        }
    );

    useEffect(() => {
        const list = selectedFlatRows.map((d) => d.original);
        if (setSelectedRows) setSelectedRows(list);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedRowIds]);

    function getPagination(hideGoToPage) {
        if (!data || !data.length || data.length <= defaultPageSize) return null;

        const gotoPageHtml = hideGoToPage ? null : (
            <li className="page-item">
                <div className="input-group mb-3">
                    <div className="input-group-prepend">
                        <span className="input-group-text">Go to page</span>
                    </div>
                    <input
                        type="number"
                        className="form-control"
                        min="1"
                        max={pageOptions.length}
                        value={pageIndex + 1}
                        onChange={(e) => {
                            const page = e.target.value ? Number(e.target.value) - 1 : 0;
                            gotoPage(page);
                        }}
                        style={{ width: '80pt' }}
                    />
                </div>
            </li>
        );
        return (
            <div className="row">
                <div className="col-sm">
                    <ul className="pagination pagination-left-part">
                        <li className="page-item">
                            <button className="page-link" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                                <i className="fa fa-fast-backward" />
                            </button>
                        </li>
                        <li className="page-item">
                            <button className="page-link" onClick={() => previousPage()} disabled={!canPreviousPage}>
                                <i className="fa fa-backward" />
                            </button>
                        </li>
                        <li className="page-item">
                            <div className="pagination-pg-info">
                                Page {pageIndex + 1} of {pageOptions.length}
                            </div>
                        </li>
                        <li className="page-item">
                            <button className="page-link" onClick={() => nextPage()} disabled={!canNextPage}>
                                <i className="fa fa-forward" />
                            </button>
                        </li>
                        <li className="page-item">
                            <button className="page-link" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                                <i className="fa fa-fast-forward" />
                            </button>
                        </li>
                    </ul>
                </div>
                <div className="col-sm">
                    <div className="pagination pagination-right-part">{gotoPageHtml}</div>
                </div>
            </div>
        );
    }

    const pagination = defaultPageSize ? getPagination(hideGoToPage) : null;

    if (onRowClicked && !rowClassName) rowClassName = 'clickable-row';

    return (
        <>
            <table className="tab-table table table-sm table-striped table-bordered table-hover" {...getTableProps()}>
                <thead className="thead-dark">
                    <tr>
                        {
                            //prettier-ignore
                            headers.map((column, colIdx) => <th scope="col" key={colIdx} {...column.getHeaderProps([
              {
                className: column.headerClassName,
                style: column.headerStyle,
              },
              getColumnProps(column),
              getHeaderProps(column),
            ])}>{column.render('Header')}</th>)
                        }
                    </tr>
                </thead>
                <tbody {...getTableBodyProps()}>
                    {
                        //prettier-ignore
                        page.map(row => prepareRow(row) || (
            <tr key={row.original._id || row.id} {...row.getRowProps()} className={rowClassName} data-row-idx={row.original._id || '@'} onClick={onRowClicked}>
              {row.cells.map((cell, ci) => (<td key={ci} {...cell.getCellProps([
                  {
                    className: cell.column.className,
                    style: cell.column.style,
                  },
                  getColumnProps(cell.column),
                  getCellProps(cell),
                ])}>{cell.render('Cell')}</td>))}
            </tr>
          ))
                    }
                </tbody>
            </table>
            {pagination}
        </>
    );
}
