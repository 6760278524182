import React from 'react';
import HeaderTop from '../common/HeaderTop';
import FooterBottom from '../common/FooterBottom';
import Header from '../common/Header';
import Utils from '../common/Utils';

export default function TabBuildingUnit(props) {
    let newProps = Utils.cloneObject(props, 'nav', 'menu', 'render');
    return (
        <>
            <HeaderTop />
            <Header
                activeIdx={2}
                render={() => (
                    <Header
                        activeIdx={1}
                        nav={true}
                        menu={'menuBuilding'}
                        render={() => (
                            <div className="container-fluid">
                                <Header {...newProps} menu={'menuUnit'} noBorder={true} render={() => <div></div>} />
                            </div>
                        )}
                    />
                )}
            />
            <FooterBottom />
        </>
    );
}
