import React, { useState, useEffect } from 'react';
import './App.css';
import { Route, Switch } from 'react-router-dom';

import RoutePrivate from './common/RoutePrivate';
import RouteAdmin from './common/RouteAdmin';
import Login from './common/Login';

import Home from './users/Home';
import Alert from './users/Alert';
import Person from './users/Person';

import Building from './users/Building';
import BuildingView from './users/BuildingView';
import BuildingUnit from './users/BuildingUnit';
import BuildingUnitView from './users/BuildingUnitView';
import BuildingUnitSensor from './users/BuildingUnitSensor';
import BuildingUnitUsage from './users/BuildingUnitUsage';

import BuildingAlert from './users/BuildingAlert';
import BuildingUsage from './users/BuildingUsage';
import BuildingPublicArea from './users/BuildingPublicArea';
import BuildingCityBill from './users/BuildingCityBill';
import BuildingReport from './users/BuildingReport';

import HomeAdmin from './admin/HomeAdmin';
import Company from './admin/Company';
import CompanyView from './admin/CompanyView';
import CompanyAdmin from './admin/CompanyAdmin';
import CompanySensor from './admin/CompanySensor';
import Gateway from './admin/Gateway';
import NewSensor from './admin/NewSensor';
import Download from './admin/Download';
import Setup from './admin/Setup';
import CompanyThreshold from './admin/CompanyThreshold';

import InstallSensor from './common/InstallSensor';
import Error from './common/Error';
import Utils from './common/Utils';

import { ContextFilterSettings } from './common/ContextFilterSettings';

function App() {
    const [filterSettings, setFilterSettings] = useState('');

    useEffect(() => {
        Utils.options.setFilterSettings = setFilterSettings;
        return () => (Utils.options.setFilterSettings = null);
    }, []);

    return (
        <main>
            <ContextFilterSettings.Provider value={filterSettings}>
                <Switch>
                    <RoutePrivate path="/" component={Home} exact />
                    <RoutePrivate path="/alert" component={Alert} />

                    <RoutePrivate path="/building-view/:bId?" component={BuildingView} />
                    <RoutePrivate path="/building-unit-view/:bId?/:uId?" component={BuildingUnitView} />
                    <RoutePrivate path="/building-unit-sensor/:bId?/:uId?" component={BuildingUnitSensor} />
                    <RoutePrivate path="/building-unit-usage/:bId?/:uId?" component={BuildingUnitUsage} />

                    <RoutePrivate path="/building-unit/:bId?/:uId?" component={BuildingUnit} />
                    <RoutePrivate path="/building-alert/:bId?/:uId?" component={BuildingAlert} />
                    <RoutePrivate path="/building-usage/:bId?/:uId?" component={BuildingUsage} />
                    <RoutePrivate path="/building-public-area/:bId?/:uId?" component={BuildingPublicArea} />
                    <RoutePrivate path="/building-bill/:bId?/:uId?" component={BuildingCityBill} />
                    <RoutePrivate path="/building-report/:bId?/:uId?" component={BuildingReport} />
                    <RoutePrivate path="/building" component={Building} exact />

                    <RouteAdmin path="/admin/company-view/:mId?" component={CompanyView} />
                    <RouteAdmin path="/admin/company-admin/:mId?" component={CompanyAdmin} />
                    <RouteAdmin path="/admin/company-sensor/:mId?" component={CompanySensor} />
                    <RouteAdmin path="/admin/company-threshold/:mId?" component={CompanyThreshold} />

                    <RouteAdmin path="/admin/company" component={Company} />
                    <RouteAdmin path="/admin/gateway" component={Gateway} />
                    <RouteAdmin path="/admin/new-sensor" component={NewSensor} />
                    <RouteAdmin path="/admin/download" component={Download} />
                    <RouteAdmin path="/admin/setup" component={Setup} />
                    <RouteAdmin path="/admin" component={HomeAdmin} />

                    <RoutePrivate path="/person" component={Person} exact />

                    <Route path="/login" component={Login} />
                    <Route path="/as" component={InstallSensor} />

                    <Route component={Error} />
                </Switch>
            </ContextFilterSettings.Provider>
        </main>
    );
}

export default App;
