import React from 'react';
import Utils from '../common/Utils';
import MessageInline from '../common/MessageInline';

export default function DialogAddUnit(props) {
    function setState(name, e, tp) {
        if (e) {
            let v = e.target.value;
            if (tp) v = isNaN(v) ? 0 : parseInt(v);
            const s = { [name]: v };
            if (props.setState) props.setState((m) => ({ ...m, ...s }));
        }
    }

    const unitAddress = { marginTop: '0.5rem', marginBottom: '1.5rem' };
    const u = props.unit;
    const numberProps = { maxLength: 64, type: 'number', min: 0, max: 999, className: 'form-control' };
    const textProps = { maxLength: 64, type: 'text', className: 'form-control' };

    return (
        <>
            <h5 style={unitAddress}>{Utils.formatAddress(props.building)}</h5>
            <form>
                <div className="form-group row">
                    <label htmlFor="fieldUnitName" className="form-label col-form-label col-sm-3">
                        Unit #:
                    </label>
                    <div className="col-sm-9">
                        <input {...textProps} id="fieldUnitName" autoFocus={true} value={u.name} onChange={(e) => setState('name', e)} />
                    </div>
                </div>
                <div className="form-group row">
                    <label htmlFor="fieldTenantName" className="form-label col-form-label col-sm-3">
                        Name of Tenant:
                    </label>
                    <div className="col-sm-9">
                        <input {...textProps} id="fieldTenantName" value={u.tenant} onChange={(e) => setState('tenant', e)} />
                    </div>
                </div>
                <div className="form-group row">
                    <label htmlFor="fieldTenantCnt" className="form-label col-form-label col-sm-3">
                        # of People:
                    </label>
                    <div className="col-sm-9">
                        <input {...numberProps} id="fieldTenantCnt" value={u.tenantCnt || ''} onChange={(e) => setState('tenantCnt', e, 'number')} />
                    </div>
                </div>
                <div className="form-group row">
                    <label htmlFor="fieldBathroomCnt" className="form-label col-form-label col-sm-3">
                        # of Bathroom:
                    </label>
                    <div className="col-sm-9">
                        <input {...numberProps} id="fieldBathroomCnt" value={u.bathroomCnt || ''} onChange={(e) => setState('bathroomCnt', e, 'number')} />
                    </div>
                </div>
            </form>
            <MessageInline msg={props.msg} setMsg={props.setMsg} />
        </>
    );
}
