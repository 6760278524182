import React, { useState } from 'react';
import HeaderTop from '../common/HeaderTop';
import FooterBottom from '../common/FooterBottom';
import Header from '../common/Header';
import CounterCard from '../common/CounterCard';
import Utils, { useShouldUpdate } from '../common/Utils';
import useApiGetData from '../common/useApiGetData';
import WaitIconInline from '../common/WaitIconInline';

function HomeDetail(props) {
    const [loading, setLoading] = useState(true);
    const [state, setState] = useState({
        alerts: 0,
        sensors: 0,
        gallons: 0,
        props: 0,
        units: 0,
        gallonsPerUnit: 0,
        optionLoadTime: 0,
    });
    const shouldUpdate = useShouldUpdate();

    useApiGetData(
        'home',
        'CacheHomeDetail',
        setState,
        (data) => {
            data.gallonsPerUnit = data.units > 0 ? Utils.formatFloat(data.gallons / data.units) : 'N/A';
            data.gallons = Utils.formatFloat(data.gallons);

            if (shouldUpdate.current && props.setAlertCnt) props.setAlertCnt(data.alerts);

            return data;
        },
        null,
        setLoading
    );

    return loading ? (
        <WaitIconInline />
    ) : (
        <div className="container-fluid">
            {/*
      <div className="row card-like-row">
        <button type="button" className="btn btn-primary" onClick={null}>
          Add Sensor
        </button>
      </div>
      */}
            <div className="row">
                <div className="col no-margin">
                    <CounterCard title="Alerts" number={state.alerts} alertColor={true} />
                </div>
                <div className="col no-margin">
                    <CounterCard title="MicroMeters" number={state.sensors} />
                </div>
                <div className="col no-margin">
                    <CounterCard title="Gallons Used" number={state.gallons} />
                </div>
            </div>
            <div className="row">
                <div className="col no-margin">
                    <CounterCard title="Properties" number={state.props} />
                </div>
                <div className="col no-margin">
                    <CounterCard title="Units" number={state.units} />
                </div>
                <div className="col no-margin">
                    <CounterCard title="Gallons by Units" number="-" />
                </div>
            </div>
        </div>
    );
}

export default function Home() {
    const [alertCnt, setAlertCnt] = useState(NaN);
    return (
        <>
            <HeaderTop />
            <Header activeIdx={0} alertCnt={alertCnt} render={(props) => <HomeDetail {...props} setAlertCnt={setAlertCnt} />} />
            <FooterBottom />
        </>
    );
}
