import React, { useEffect, useRef } from 'react';

export default function Modal(props) {
    const eventAdded = useRef(false);
    const btnLabelOk = props.lbOk || props.btnLabelOk || null;
    const btnLabelCancel = props.lbCancel || props.btnLabelCancel || 'Close';

    function handleOnCancel() {
        if (props.onClose) props.onClose();
        else if (props.toggle && props.show) props.toggle((c) => !c);
    }

    function handleOnOK() {
        if (!props.onOk || props.onOk()) handleOnCancel();
    }

    function escFunction(event) {
        if (event && event.keyCode === 27) handleOnCancel();
    }

    useEffect(() => {
        if (!eventAdded.current && props.show) {
            document.addEventListener('keydown', escFunction, false);
            eventAdded.current = true;
        }
        return () => {
            if (eventAdded.current) {
                document.removeEventListener('keydown', escFunction, false);
                eventAdded.current = false;
            }
        };
    });

    const okButton = btnLabelOk ? (
        <button type="button" className="btn btn-info btn-width-normal" onClick={handleOnOK}>
            {btnLabelOk}
        </button>
    ) : null;

    const title = props.title || props.dialogTitle || 'Information';

    return props.show ? (
        <div className="modal-back-drop">
            <div className="modal-dialog-box">
                <div className="dialog-header bg-info">
                    <h5 className="modal-title text-white">{title}</h5>
                    <button type="button" className="close" onClick={handleOnCancel}>
                        <span aria-hidden="true">&nbsp;&times;&nbsp;</span>
                    </button>
                </div>
                <div className="modal-body">{props.children}</div>
                <div className="modal-footer">
                    {okButton}&nbsp;&nbsp;
                    <button type="reset" className="btn btn-info btn-width-normal" onClick={handleOnCancel}>
                        {btnLabelCancel}
                    </button>
                </div>
            </div>
        </div>
    ) : null;
}
