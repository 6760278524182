import React from 'react';
import Utils from '../common/Utils.js';

export default function AlertViewDlg(props) {
    const a = props.alert || {
        _id: '',
        createdTime: 0,
        sn: '',
        street: '',
        city: '',
        state: '',
        uName: '',
        rName: '',
        appName: '',
        tName: '',
        closeDate: 0,
        comments: [],
    };

    const cms = a.comments;
    const cmsText =
        cms && cms.length ? (
            <div className="container-fluid">
                {cms.map((e, idx) => (
                    <div className="row" key={idx}>
                        <div className="col-">{Utils.formatDateTime(e.ts)}: </div>
                        <div className="col-sm">{e.note}</div>
                    </div>
                ))}
            </div>
        ) : (
            'N/A'
        );

    return (
        <div className="table-responsive-sm">
            <table className="table table-alert-detail table-borderless">
                <tbody>
                    <tr>
                        <td>Alert ID:</td>
                        <td>{a._id}</td>
                    </tr>
                    <tr>
                        <td>Alert date:</td>
                        <td>{Utils.formatDateTime(a.createdTime)}</td>
                    </tr>
                    <tr>
                        <td>Building:</td>
                        <td>{Utils.formatAddress(a)}</td>
                    </tr>
                    <tr>
                        <td>Unit#:</td>
                        <td>{a.uName}</td>
                    </tr>
                    <tr>
                        <td>MicroMeter SN:</td>
                        <td>{a.sn}</td>
                    </tr>
                    <tr>
                        <td>Room:</td>
                        <td>{a.rName}</td>
                    </tr>
                    <tr>
                        <td>Water appliance:</td>
                        <td>{a.appName}</td>
                    </tr>
                    <tr>
                        <td>Water type:</td>
                        <td>{a.tName}</td>
                    </tr>
                    <tr>
                        <td>Close date:</td>
                        <td>{Utils.formatDateTime(a.closeDate)}</td>
                    </tr>
                    <tr>
                        <td>Notes:</td>
                        <td>{cmsText}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
}
