import React, { useState } from 'react';
import HeaderBuilding from '../common/HeaderBuilding';
import TabBuilding from '../users/TabBuilding';
import Utils from '../common/Utils';
import CounterCard from '../common/CounterCard';
import { Redirect, useParams } from 'react-router-dom';
import useGetBuildingById, { defaultBuilding } from '../common/GetBuildingById';

function BuildingViewDetail() {
    const [bd, setBuilding] = useState(defaultBuilding);
    const { bId } = useParams();
    const badId = Utils.isBadId(bId);

    useGetBuildingById(bId, setBuilding, badId);

    if (badId) return <Redirect to={'/building'} />;

    return (
        <div className="container-fluid">
            <HeaderBuilding address={Utils.formatAddress(bd)} manager={bd.managerName} pic={bd.pic} />

            <div className="row">
                <div className="col no-margin">
                    <CounterCard title="Alerts" number={bd.alerts} alertColor={true} />
                </div>
                <div className="col no-margin">
                    <CounterCard title="MicroMeters" number={bd.sensorCnt || bd.sensors.length} />
                </div>
                <div className="col no-margin">
                    <CounterCard title="Gallons Used" number={Utils.formatFloat(bd.gallons)} />
                </div>
            </div>
            <div className="row">
                <div className="col no-margin">
                    <CounterCard title="Units" number={bd.units.length} />
                </div>
                <div className="col no-margin">
                    <CounterCard title="Gallons by Units" number="-" />
                </div>
                <div className="col no-margin">
                    <CounterCard title="Graph" number="-" />
                </div>
            </div>
            <div className="row">
                <div className="col no-margin">
                    <CounterCard title="Map" number="-" />
                </div>
                <div className="col no-margin" />
                <div className="col no-margin" />
            </div>
        </div>
    );
}

export default function BuildingView(props) {
    const newProps = Utils.cloneObject(props, 'activeIdx', 'render');
    return <TabBuilding {...newProps} activeIdx={0} render={(props) => <BuildingViewDetail {...props} />} />;
}
