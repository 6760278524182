import React from 'react';
import Utils from '../common/Utils';

export default function HeaderCompany(props) {
    const title = props.title || props.name || '';
    const address = props.address || '';

    let btn = null;
    if (props.leftMenuItem) {
        const menuItems = props.leftMenuItem.map((item) => (
            <button className="dropdown-item" key={item.title} onClick={item.onClicked}>
                {item.title}
            </button>
        ));

        const dropdownMenuStyle = {
            position: 'absolute',
            top: '0px',
            left: '0px',
            transform: 'translate3d(0px, 38px, 0px)',
            willChange: 'transform',
        };

        const paddingTopBtn = { paddingTop: '16px' };
        btn = (
            <div className="col-sm-auto align-self-top" style={paddingTopBtn}>
                <div className="btn-group">
                    <button
                        aria-haspopup="true"
                        aria-expanded="false"
                        data-toggle="dropdown"
                        id="dropdown-item-button"
                        type="button"
                        className="dropdown-toggle btn btn-primary"
                    >
                        Action
                    </button>
                    <div x-placement="bottom-start" aria-labelledby="dropdown-item-button" className="dropdown-menu" style={dropdownMenuStyle}>
                        {menuItems}
                    </div>
                </div>
            </div>
        );
    }

    const pic = Utils.base() + (props.pic ? 'img/building/' + props.pic : 'img/company.png');
    const paddingTopText = { paddingTop: '10px' };
    return (
        <div className="row">
            {btn}
            <div className="col align-self-center">
                <h5 style={paddingTopText}>{title}</h5>
                <div>{address}</div>
            </div>
            <div className="col-sm-auto">
                <img src={pic} className="img-thumbnail" alt="{title}" width="96" height="96" />
            </div>
        </div>
    );
}
