import React, { useCallback } from 'react';
import Utils from '../common/Utils';
import MessageInline from '../common/MessageInline';

export default function DialogFilterSettings(props) {
    function roundDate(d) {
        if (d && d.setDate) d.setDate(15);
        return d;
    }

    const setHideSensorDetail = useCallback(
        (e) => {
            if (e && e.target) e.target.value = e.target.checked ? 1 : 0;
            Utils.setState(props, 'hideSensorDetail', e, 0, 1);
        },
        [props]
    );

    const parseMonthInput = useCallback((ds) => {
        if (!ds) return null;
        ds = ('' + ds).trim();
        if (!isNaN(ds)) return roundDate(new Date(ds));

        var nd = Date.parse(ds);
        if (!isNaN(nd)) nd = roundDate(new Date(nd));
        else nd = null;

        var reg = /^(\d+)[\s/\\-](\d+)$/;
        var m = reg.exec(ds);
        if (!m || m.length < 3) return nd;
        var a = parseInt(m[1]);
        var b = parseInt(m[2]);
        if (isNaN(a) || isNaN(b)) return nd;

        var mm = b;
        var yy = a;
        if (b > 12) {
            yy = b;
            mm = a;
        }
        var php = true;
        if (yy && yy < 1000) {
            php = false;
            yy += 2000;
        }
        if (!yy || !mm || mm > 12) return nd;
        if (php && nd) return nd;
        return new Date(yy, mm - 1, 15);
    }, []);

    const calcFirstLastDate = (d) => {
        const y = d.getFullYear();
        const m = d.getMonth();
        const filterFirstDay = new Date(y, m, 1).getTime();
        const filterLastDay = new Date(y, m + 1, 0, 23, 59, 59).getTime();
        return [filterFirstDay, filterLastDay];
    };

    const onMonthChanged = useCallback(
        (e) => {
            if (e) {
                const d = roundDate(parseMonthInput(e.target.value));
                const [filterFirstDay, filterLastDay] = calcFirstLastDate(d);
                props.setState((m) => ({ ...m, filterFirstDay, filterLastDay }));
            }
        },
        [parseMonthInput, props]
    );

    const onDateChanged = (e, lastDate) => {
        if (e && e.target) {
            let d = Date.parse(e.target.value);
            if (isNaN(d)) return false;
            if (lastDate) {
                d = d + (86400000 - 1);
                props.setState((m) => ({ ...m, filterLastDay: d }));
            } else props.setState((m) => ({ ...m, filterFirstDay: d }));
        }
    };

    const onFilterTypeChanged = useCallback(
        (e) => {
            if (e && e.target) {
                const filterType = parseInt(e.target.value) || 1;
                Utils.setState(props, 'filterType', e, 1, 3);
                if (filterType === 2) {
                    const ft = props.filter || {};
                    let d = !isNaN(ft.filterFirstDay) ? new Date(ft.filterFirstDay) : new Date();
                    d.setDate(15);
                    const [filterFirstDay, filterLastDay] = calcFirstLastDate(d);
                    props.setState((m) => ({ ...m, filterFirstDay, filterLastDay }));
                }
            }
        },
        [props]
    );

    const ft = props.filter || {};
    const filterMonth = Utils.formatMonth(roundDate(!isNaN(ft.filterFirstDay) ? new Date(ft.filterFirstDay) : new Date()));
    const filterType = parseInt(ft.filterType) || 1;
    const hideDetail = parseInt(ft.hideSensorDetail) === 1;

    const firstDay = Utils.formatDateInput(ft.filterFirstDay ? new Date(ft.filterFirstDay) : new Date());
    const lastDay = Utils.formatDateInput(ft.filterLastDay ? new Date(ft.filterLastDay) : new Date());

    const labelSt = { className: 'form-check-label', style: { paddingBottom: '0.25rem' } };
    const numSt = { type: 'number', className: 'form-control', min: 1, max: 9999, placeholder: '# of days' };
    const monthSt = { type: 'month', className: 'form-control' };
    const dateSt = { type: 'date', className: 'form-control' };

    return (
        <>
            <form>
                <div className="form-group">
                    <label {...labelSt} htmlFor="filterComboBox">
                        Filter Type
                    </label>
                    <select className="form-control" name="filterComboBox" id="filterComboBox" value={filterType} onChange={onFilterTypeChanged}>
                        <option value="1">Filter by last # of days</option>
                        <option value="2">Filter by Month</option>
                        <option value="3">Filter by Date range</option>
                    </select>
                </div>

                <div className={filterType === 1 ? 'form-group' : 'hidden'}>
                    <input {...numSt} id="selDay" name="selDay" onChange={(e) => Utils.setState(props, 'numDays', e, 1, 99999)} value={ft.numDays || ''} />
                </div>

                <div className={filterType === 2 ? 'form-group' : 'hidden'}>
                    <input {...monthSt} id="selMonth" name="selMonth" onChange={onMonthChanged} value={filterMonth} />
                </div>

                <div className={filterType === 3 ? 'form-group' : 'hidden'}>
                    <label {...labelSt} htmlFor="firstDay">
                        From date
                    </label>
                    <input {...dateSt} id="firstDay" name="firstDay" onChange={onDateChanged} value={firstDay} />
                </div>

                <div className={filterType === 3 ? 'form-group' : 'hidden'}>
                    <label {...labelSt} htmlFor="lastDay">
                        To date
                    </label>
                    <input {...dateSt} id="lastDay" name="lastDay" onChange={(e) => onDateChanged(e, true)} value={lastDay} />
                </div>

                <div className="form-check">
                    <input
                        className="form-check-input"
                        type="checkbox"
                        name="hideSensorDetail"
                        id="hideSensorDetail"
                        checked={hideDetail}
                        onChange={(e) => setHideSensorDetail(e)}
                    />
                    <label {...labelSt} htmlFor="hideSensorDetail">
                        Hide MicroMeter details
                    </label>
                </div>
            </form>
            <MessageInline msg={props.msg} setMsg={props.setMsg} />
        </>
    );
}
