import useApiGetData from '../common/useApiGetData';
import { AppCache } from './MyCache';

const cacheKey = 'UnitViewDetail';

export const defaultUnit = {
    _id: 0,
    name: '',
    tenant: '',
    oAlerts: 0,
    cAlerts: 0,
    area: 0,
    sensors: [],
    tenantCnt: 0,
    gallons: 0,
};

export function GetUnitJson(unit) {
    if (!unit && !unit._id) return defaultUnit;
    return unit;
}

export function addUnitToCache(unit) {
    if (unit && unit._id) {
        const u = GetUnitJson(unit);
        if (u !== defaultUnit) AppCache.add(cacheKey + unit._id, u);
    }
}

export default function useGetUnitById(uId, setUnit, skip = false, fnSetLoading = null) {
    useApiGetData(
        'unit',
        cacheKey + uId,
        setUnit,
        (data) => {
            return Array.isArray(data) && data.length && data[0] ? GetUnitJson(data[0]) : defaultUnit;
        },
        { param: { uId }, skip },
        fnSetLoading
    );
}
