import React, { useState, useCallback } from 'react';
import HeaderTopAdmin from '../admin/HeaderTopAdmin';
import Header from '../common/Header';
import MessageInline from '../common/MessageInline';
import Utils from '../common/Utils';
import useApiGetData from '../common/useApiGetData';
import WaitIconInline from '../common/WaitIconInline';

function DialogGlobalSettings(props) {
    const inputProps = { type: 'number', min: '1', max: '9999', className: 'form-control', maxLength: '8', autoFocus: true };
    const d = props.settings || {};
    return (
        <>
            <div className="container-fluid">
                <div>&nbsp;</div>
                <h4>System global settings</h4>
                <div>&nbsp;</div>
                <form>
                    <div className="form-group row">
                        <label htmlFor="fieldUnitName" className="form-label col-form-label col-sm-6">
                            Submit Interval (minutes):
                        </label>
                        <div className="col-sm-6">
                            <input {...inputProps} value={d.gsmInterval} onChange={(e) => Utils.setState(props, 'gsmInterval', e, 1, 9999)} />
                        </div>
                    </div>
                    <div className="form-group row">
                        <div className="col-sm-12">
                            <button type="button" className="btn btn-primary" onClick={props.onSave}>
                                Save
                            </button>
                        </div>
                    </div>
                </form>
                <MessageInline msg={props.msg} setMsg={props.setMsg} />
            </div>
        </>
    );
}

function SetupDetail() {
    const [settings, setSettings] = useState({ gsmInterval: 120 });
    const [msg, setMsg] = useState('');
    const [infoMsg, setInfoMsg] = useState('');
    const [loading, setLoading] = useState(true);
    const CacheKey = 'GlobalSettings';
    const shouldUpdate = Utils.useShouldUpdate();

    useApiGetData('global-settings', CacheKey, setSettings, null, null, setLoading);

    const onSave = useCallback(() => {
        if (isNaN(settings.gsmInterval) || settings.gsmInterval < 1) setMsg('Invalid submit interval');
        else {
            const data = { ...settings };
            Utils.apiPostData(
                'global-settings',
                CacheKey,
                { settings: data },
                () => {
                    if (shouldUpdate.current) {
                        setMsg('');
                        setInfoMsg('Save successfully');
                        setSettings(data);
                    }
                    return data;
                },
                (err, errMsg) => setMsg(errMsg || 'Failed to save data')
            );
        }
    }, [settings, shouldUpdate]);

    return loading ? (
        <WaitIconInline />
    ) : (
        <>
            <DialogGlobalSettings msg={msg} setMsg={setMsg} settings={settings} setState={setSettings} onSave={onSave} />
            <MessageInline msg={infoMsg} setMsg={setInfoMsg} info={true} />
        </>
    );
}

export default function Setup() {
    return (
        <>
            <HeaderTopAdmin />
            <Header menu={'menuAdmin'} activeIdx={5} render={(props) => <SetupDetail {...props} />} />
        </>
    );
}
