import React, { useState, useCallback, useEffect } from 'react';
import Select from 'react-select';
import Utils, { useShouldUpdate } from '../common/Utils';
import MessageInline from '../common/MessageInline';
import useApiGetData from '../common/useApiGetData';

export default function DialogAddBuilding(props) {
    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([]);
    const [managers, setManagers] = useState([]);
    const bd = props.building;
    const mId = Utils.mgmtId();

    // load list of states
    const mapValueFn = useCallback((d) => d.map((e) => ({ value: e._id, label: e.name })), []);

    useApiGetData('table-state', 'StateList', setStates, mapValueFn);

    // load list of manager.
    const mapManagerFn = useCallback((d) => d.map((e) => ({ value: e._id, label: e.firstName + ' ' + e.lastName, fn: e.firstName, ln: e.lastName })), []);
    useApiGetData('people', 'ManagerList' + mId, setManagers, mapManagerFn, { param: { role: Utils.ROLES_MANAGER } });

    // helper to load list of cities
    const shouldUpdate = useShouldUpdate();
    function loadCities(stateId) {
        Utils.apiGetData(stateId && 'table-city', 'CityList' + stateId, setCities, mapValueFn, { param: { stateId } }, null, shouldUpdate);
    }

    useEffect(() => {
        if (bd.state) loadCities(bd.state.value);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function setState(name, e, tp) {
        if (e) {
            let v = e.target.value;
            if (tp) v = isNaN(v) ? 0 : parseInt(v);
            const s = { [name]: v };
            if (props.setState) props.setState((m) => ({ ...m, ...s }));
        }
    }

    function onStateChange(state) {
        if (!props.setState) return;

        if (bd.state !== state) {
            props.setState((m) => ({ ...m, state, city: null }));
            if (state !== null) loadCities(state.value);
        }
    }

    function onCityChange(city) {
        if (props.setState) props.setState((m) => ({ ...m, city }));
    }

    function onManagerChange(manager) {
        if (props.setState) props.setState((m) => ({ ...m, manager }));
    }

    return (
        <>
            <form>
                <div className="form-group row">
                    <label className="form-label col-form-label col-sm-3">Street and #:</label>
                    <div className="col-sm-9">
                        <input
                            type="text"
                            maxLength="128"
                            className="form-control"
                            autoFocus={true}
                            value={bd.street}
                            onChange={(e) => setState('street', e)}
                        />
                    </div>
                </div>

                <div className="form-group row">
                    <label className="form-label col-form-label col-sm-3">State:</label>
                    <div className="col-sm-9">
                        <Select classNamePrefix="react-select-focus" isClearable={true} options={states} onChange={onStateChange} value={bd.state} />
                    </div>
                </div>

                <div className="form-group row">
                    <label className="form-label col-form-label col-sm-3">City:</label>
                    <div className="col-sm-9">
                        <Select classNamePrefix="react-select-focus" isClearable={true} options={cities} onChange={onCityChange} value={bd.city} />
                    </div>
                </div>

                <div className="form-group row">
                    <label className="form-label col-form-label col-sm-3">Manager:</label>
                    <div className="col-sm-9">
                        <Select classNamePrefix="react-select-focus" isClearable={true} options={managers} onChange={onManagerChange} value={bd.manager} />
                    </div>
                </div>

                <div className="form-group row">
                    <label className="form-label col-form-label col-sm-3">Photo:</label>
                    <div className="col-sm-9">
                        <input type="text" className="form-control" disabled={true} defaultValue={'Click to select photo'} />
                    </div>
                </div>
            </form>
            <MessageInline msg={props.msg} setMsg={props.setMsg} />
        </>
    );
}
