import React, { useState, useEffect, useContext } from 'react';
import HeaderUnit from '../common/HeaderUnit';
import TabBuildingUnit from '../users/TabBuildingUnit';
import Utils from '../common/Utils';
import useGetBuildingById, { defaultBuilding } from '../common/GetBuildingById';
import useGetUnitById, { defaultUnit } from '../common/GetUnitById';
import useApiGetData from '../common/useApiGetData';
import { Redirect, useParams } from 'react-router-dom';
import WaitIconInline from '../common/WaitIconInline';
import { AppCache } from '../common/MyCache';
import { ContextFilterSettings } from '../common/ContextFilterSettings';

function BuildingUnitUsageDetail() {
    const [state, setState] = useState({ sensors: [] });
    const [bd, setBuilding] = useState(defaultBuilding);
    const [unit, setUnit] = useState(defaultUnit);
    const [loading, setLoading] = useState(true);
    const [sortType, setSortType] = useState(0);
    const filterSettings = useContext(ContextFilterSettings);
    const { bId, uId } = useParams();
    const badId = Utils.isBadId(bId);

    useGetBuildingById(bId, setBuilding, badId);
    useGetUnitById(uId, setUnit, badId);

    const options = { param: { uId, sort: sortType }, skip: badId };
    const cacheKey = 'UnitUsageSensorList' + bId + 'u' + uId;

    useApiGetData('sample', cacheKey, setState, (data) => ({ sensors: data }), options, setLoading);

    useEffect(() => {
        setState((s) => {
            if (Array.isArray(s.sensors) && s.sensors.length) {
                const sortedSensors = s.sensors.sort((a, b) => (sortType ? (a._id === b._id ? b.ct - a.ct : a._id - b._id) : b.ct - a.ct));
                const ns = { sensors: sortedSensors };
                AppCache.add(cacheKey, ns);
                return ns;
            } else return s;
        });
    }, [cacheKey, sortType]);

    const sortByTimestamp = () => setSortType(0);
    const sortById = () => setSortType(1);

    const sensorDataSortBy = [
        { title: 'Timestamp', onClicked: sortByTimestamp },
        { title: 'MicroMeter SN', onClicked: sortById },
    ];

    if (badId) return <Redirect to={'/building'} />;

    let showCol = Utils.showSensorDetail(filterSettings);

    function getBatteryStr(e) {
        return <td>{Math.floor(e.battery + 0.5) + '%'}</td>;
    }

    return loading ? (
        <WaitIconInline />
    ) : (
        <div className="container-fluid table-margin">
            <HeaderUnit name={unit.name} address={Utils.formatAddress(bd)} tenant={unit.tenant} sortByMenuItem={sensorDataSortBy} />
            <div className="table-responsive-sm">
                <table className="tab-table table table-sm table-striped table-bordered table-hover">
                    <thead className="thead-dark">
                        <tr>
                            <th scope="col">Timestamp</th>
                            <th scope="col">MicroMeter SN</th>
                            <th scope="col">Location</th>
                            <th scope="col">Gallons</th>
                            {showCol ? <th scope="col">{'Battery'}</th> : null}
                        </tr>
                    </thead>
                    <tbody>
                        {state.sensors.map((e, idx) => (
                            <tr key={idx + '_' + e._id}>
                                <td>{Utils.formatDateTime(e.ct)}</td>
                                <td>{e._id}</td>
                                <td>
                                    {e.rName}, {e.appName}, {e.tName}
                                </td>
                                <td>{Utils.formatFloat(e.dt)}</td>
                                {showCol ? getBatteryStr(e) : null}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default function BuildingUnitUsage(props) {
    let newProps = Utils.cloneObject(props, 'activeIdx', 'render');
    return <TabBuildingUnit {...newProps} activeIdx={2} component={BuildingUnitUsageDetail} />;
}
