import React, { useState } from 'react';
import MyTable from '../common/MyTable';
import Utils from '../common/Utils';
import MessageInline from '../common/MessageInline';
import useApiGetData from '../common/useApiGetData';
import WaitIconInline from '../common/WaitIconInline';

export default function DialogGrantSensor(props) {
    const [sensors, setSensors] = useState([]);
    const [loading, setLoading] = useState(true);

    const tableColumns = [
        { Header: 'SN', accessor: 'sn' },
        { Header: 'Status', accessor: 'status', Cell: (props) => Utils.formatSensorStatus(props.cell.value) },
        {
            Header: 'Created',
            accessor: 'installedDate',
            Cell: (props) => Utils.formatDate(props.cell.value),
        },
    ];

    useApiGetData('sensor-info', 'AvailSensors', setSensors, null, null, setLoading);

    return loading ? (
        <WaitIconInline />
    ) : (
        <>
            <div className="table-responsive-sm">
                <div>Please select MicroMeters to add to the current management company:</div>
                <MyTable columns={tableColumns} data={sensors} defaultPageSize={10} setSelectedRows={props.setSelectedRows} />
            </div>
            <MessageInline msg={props.msg} setMsg={props.setMsg} />
        </>
    );
}
