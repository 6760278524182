import React from 'react';
import HeaderTop from '../common/HeaderTop';
import FooterBottom from '../common/FooterBottom';
import Header from '../common/Header';
import AlertList from '../common/AlertList';
import HeaderSearch from '../common/HeaderSearch';

export default function Alert() {
    const menu = [{ title: 'ID' }, { title: 'First Report' }, { title: 'Type' }];

    return (
        <>
            <HeaderTop />
            <Header
                activeIdx={1}
                render={(props) => (
                    <div className="container-fluid table-margin">
                        <HeaderSearch menuItems={menu} />
                        <div className="table-responsive-sm">
                            <AlertList {...props} />
                        </div>
                    </div>
                )}
            />
            <FooterBottom />
        </>
    );
}
