import React, { useState, useCallback } from 'react';
import { Redirect } from 'react-router-dom';
import HeaderTopAdmin from '../admin/HeaderTopAdmin';
import Header from '../common/Header';
import useApiGetData from '../common/useApiGetData';
import WaitIconInline from '../common/WaitIconInline';
import Utils, { useShouldUpdate } from '../common/Utils';
import { AppCache } from '../common/MyCache';

function CompanyDetail() {
    const [companys, setCompany] = useState([]);
    const [loading, setLoading] = useState(true);
    const [clickedItem, setClickedItem] = useState(null);
    const shouldUpdate = useShouldUpdate();

    useApiGetData('company', 'CompanyDetailList', setCompany, null, null, setLoading);

    const onCompanyClicked = useCallback(
        (e) => {
            const item = Utils.getRowData(e, companys);
            if (item && shouldUpdate.current) setClickedItem(item);
        },
        [companys, shouldUpdate]
    );

    if (clickedItem && clickedItem._id) {
        const cacheKey = 'CompanyDetail' + clickedItem._id;
        AppCache.add(cacheKey, clickedItem);
        return <Redirect to={'/admin/company-view/' + clickedItem._id} />;
    }

    return loading ? (
        <WaitIconInline />
    ) : (
        <div className="table-responsive-sm">
            <table className="tab-table table table-sm table-striped table-bordered table-hover">
                <thead className="thead-dark">
                    <tr>
                        <th scope="col">Name</th>
                        <th scope="col">Street</th>
                        <th scope="col">City</th>
                        <th scope="col">State</th>
                        <th scope="col"># Properties</th>
                    </tr>
                </thead>
                <tbody>
                    {companys.map((e) => (
                        <tr key={e._id} onClick={onCompanyClicked} data-row-idx={e._id} className="clickable-row">
                            <td>{e.name}</td>
                            <td>{e.street}</td>
                            <td>{e.city}</td>
                            <td>{e.state}</td>
                            <td>{e.bIds.length}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}

export default function Company() {
    return (
        <>
            <HeaderTopAdmin />
            <Header menu={'menuAdmin'} activeIdx={1} render={(props) => <CompanyDetail {...props} />} />
        </>
    );
}
