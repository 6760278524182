import React, { useCallback } from 'react';
import logo from '../img/logo.png';
import Utils from '../common/Utils';
import Cookies from 'js-cookie';
import useApiGetData from '../common/useApiGetData';

export default function HeaderTopAdmin() {
    const logout = useCallback(() => {
        Utils.deleteAllCookies();
        Utils.logout();
    }, []);

    useApiGetData('admin-test');

    const email = Cookies.get('email') || '';
    return (
        <>
            <div className="row">
                <div className="col">
                    <div className="btn-group" style={{ float: 'right' }}>
                        <button type="button" className="setting-icon dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i className="fas fa-user"></i> <span className="login-email">{email}</span>
                        </button>
                        <div className="dropdown-menu dropdown-menu-right">
                            <button className="dropdown-item" type="button" onClick={logout}>
                                Logout
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col text-center">
                    <img src={logo} alt="DrizzleX" className="img-responsive" id="logo" />
                </div>
            </div>
        </>
    );
}
