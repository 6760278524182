import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import Utils from '../common/Utils';

export default function RouteAdmin({ component: Component, ...rest }) {
    switch (Utils.role()) {
        case 1:
            return <Route {...rest} render={(props) => <Component {...props} />} />;

        default:
            return <Redirect to="/login" />;
    }
}
