import React, { useState, useCallback } from 'react';
import Cookies from 'js-cookie';
import Utils from '../common/Utils';
import Modal from '../common/Modal';
import DialogFilterSettings from '../common/DialogFilterSettings';
import useApiGetData from '../common/useApiGetData';

export default function HeaderTop() {
    const [modalShown, toggleModal] = useState(false);
    const [msg, setMsg] = useState('');
    const shouldUpdate = Utils.useShouldUpdate();
    const [filter, setFilter] = useState(() => {
        const filterMonth = new Date();
        filterMonth.setDate(15);
        return { filterMonth, numDays: 30, filterType: 1, hideSensorDetail: 0 };
    });
    const cacheKey = 'GlobalOptions';

    useApiGetData('load-settings', cacheKey, setFilter, (data) => Utils.setOptionFilter(data));

    const saveFilterSetting = useCallback(() => {
        let msg = '';

        if (isNaN(filter.numDays) || filter.numDays < 1 || filter.numDays > 99999) msg = 'Invalid number of days';
        else if (!filter.filterFirstDay || !filter.filterLastDay) msg = 'Invalid month';
        else if (filter.filterFirstDay >= filter.filterLastDay) msg = 'Date range is invalid';
        else if (isNaN(filter.filterType)) msg = 'Bad filter type';

        if (msg) {
            setMsg(msg);
            return false;
        }

        let ft = filter.filterType;
        if (isNaN(ft) || ft < 1 || ft > 3) ft = 1;

        const data = { ...filter };

        Utils.apiPostData(
            'save-settings',
            cacheKey,
            data,
            () => {
                if (shouldUpdate.current) {
                    setFilter(data);
                    setMsg('');
                    toggleModal(false);
                }
                Utils.clearFilterCache();
                Utils.setOptionFilter(data);
                return data;
            },
            () => (shouldUpdate.current ? setMsg('Failed to save data') : null)
        );

        return false;
    }, [filter, shouldUpdate]);

    const logout = useCallback(() => {
        Utils.deleteAllCookies();
        Utils.logout();
    }, []);

    const email = Cookies.get('email') || '';
    return (
        <>
            <div className="row">
                <div className="col">
                    <div className="btn-group" style={{ float: 'right' }}>
                        <button type="button" className="setting-icon dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i className="fas fa-user"></i> <span className="login-email">{email}</span>
                        </button>
                        <div className="dropdown-menu dropdown-menu-right">
                            <button className="dropdown-item" type="button" onClick={logout}>
                                Logout
                            </button>
                            <button className="dropdown-item" type="button" onClick={toggleModal}>
                                Settings...
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col text-center">
                    <img src="/api/logo" alt="DrizzleX" className="img-responsive" id="logo" />
                </div>
            </div>
            <Modal title="Settings" show={modalShown} toggle={toggleModal} onOk={saveFilterSetting} lbCancel={'Cancel'} lbOk={'Save'}>
                <DialogFilterSettings filter={filter} setState={setFilter} msg={msg} setMsg={setMsg} />
            </Modal>
        </>
    );
}
