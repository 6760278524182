import React from 'react';
import HeaderTop from '../common/HeaderTop';
import FooterBottom from '../common/FooterBottom';
import Header from '../common/Header';
import Utils from '../common/Utils';

export default function TabBuilding(props) {
    let newProps = Utils.cloneObject(props, 'nav', 'menu');
    return (
        <>
            <HeaderTop />
            <Header activeIdx={2} render={() => <Header nav={true} menu={'menuBuilding'} {...newProps} />} />
            <FooterBottom />
        </>
    );
}
