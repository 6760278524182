import React from 'react';
import Utils from './Utils';

export default function HeaderUnitDashboad(props) {
    const paddingTopText = { paddingTop: '10px' };
    const paddingTopBtn = { paddingTop: '5px' };

    const items = props.sortByMenuItem
        ? props.sortByMenuItem.map((item) => (
              <button className="dropdown-item" key={item.title} onClick={item.onClicked}>
                  {item.title}
              </button>
          ))
        : null;

    const title = props.title ? props.title + ': ' : 'Current tenant: ';
    const inline = { display: 'inline' };

    const dropdownMenuStyle = {
        position: 'absolute',
        top: '0px',
        left: '0px',
        transform: 'translate3d(0px, 38px, 0px)',
        willChange: 'transform',
    };

    let btn = props.addSensorButton ? (
        <div className="col-sm-auto align-self-top" style={paddingTopBtn}>
            <button type="button" className="btn btn-primary" onClick={props.addSensorButton}>
                Configuration
            </button>
        </div>
    ) : props.sortByMenuItem ? (
        <div className="col-sm-auto align-self-top" style={paddingTopBtn}>
            <div className="btn-group">
                <button
                    aria-haspopup="true"
                    aria-expanded="false"
                    data-toggle="dropdown"
                    id="dropdown-item-button"
                    type="button"
                    className="dropdown-toggle btn btn-primary"
                >
                    Sort By
                </button>
                <div x-placement="bottom-start" aria-labelledby="dropdown-item-button" className="dropdown-menu" style={dropdownMenuStyle}>
                    {items}
                </div>
            </div>
        </div>
    ) : null;

    if (!Utils.isAdminOrManager()) btn = null;

    return (
        <div className="row unit-dashboard-header">
            {btn}
            <div className="col align-self-center">
                <h5 style={paddingTopText}>{props.address}</h5>
                <div>{props.name}</div>
                <div>
                    {title}
                    <h6 style={inline}>{props.tenant}</h6>
                </div>
            </div>
        </div>
    );
}
