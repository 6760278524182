import React, { useState } from 'react';
import HeaderTop from '../common/HeaderTop';
import FooterBottom from '../common/FooterBottom';
import Header from '../common/Header';
import WaitIconInline from '../common/WaitIconInline';
import HeaderSearch from '../common/HeaderSearch';
import Modal from '../common/Modal';
import useApiGetData from '../common/useApiGetData';
import Utils from '../common/Utils';

function PersonDetail() {
    const [loading, setLoading] = useState(true);
    const [modalShown, toggleModal] = useState(false);
    const [state, setState] = useState({ people: [] });

    // prettier-ignore
    useApiGetData('people', 'PersonDetail', setState, data => ({
      people: data.map(e => {
        if (e.picture) e.picture = Utils.base() + 'img/profile/' + e.picture;
        e.name = e.firstName + ' ' + e.lastName;
        return e;
      }),
    }),
    null, setLoading);

    return loading ? (
        <WaitIconInline />
    ) : (
        <>
            <div className="container-fluid table-margin">
                <HeaderSearch leftTitle={'Add Person'} leftBtnClick={() => toggleModal((m) => !m)} />
                <div className="table-responsive-sm">
                    <table className="tab-table table table-sm table-striped table-bordered table-hover">
                        <thead className="thead-dark">
                            <tr>
                                <th scope="col">Name</th>
                                <th scope="col">Phone</th>
                                <th scope="col">E-mail</th>
                                <th scope="col">Role</th>
                                <th scope="col" className="text-center">
                                    Picture
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {state.people.map((e) => (
                                <tr key={e._id}>
                                    <td>{e.name}</td>
                                    <td>{e.mobile}</td>
                                    <td>{e.email}</td>
                                    <td>{e.role}</td>
                                    <td className="pic-padding">
                                        <img src={e.picture} className="img-thumbnail-small" alt={e.name} width="28" height="28" />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
            <Modal title="Add Person" show={modalShown} toggle={toggleModal} lbCancel={'Cancel'} lbOk={'Submit'}>
                <h1>Form to add person here</h1>
            </Modal>
        </>
    );
}

export default function Person() {
    return (
        <>
            <HeaderTop />
            <Header activeIdx={3} component={PersonDetail} />
            <FooterBottom />
        </>
    );
}
