import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import Utils from '../common/Utils';

export default function RoutePrivate({ component: Component, ...rest }) {
    switch (Utils.role()) {
        case 1:
        case -1:
            return <Redirect to="/login" />;

        default:
            return <Route {...rest} render={(props) => <Component {...props} />} />;
    }
}
