import React from 'react';
import Utils from '../common/Utils';

export default function CounterCard(props) {
    const st = props.alertColor ? Utils.alertNumStyle(props.number) : null;
    const num = props.alertColor ? Utils.formatAlertNum(props.number) : props.number;
    return (
        <div className="card">
            <div className="card-body">
                <h5 className="card-title">{props.title}</h5>
                <div className="card-number" style={st}>
                    {num}
                </div>
            </div>
        </div>
    );
}
