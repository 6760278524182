import React, { useState, useCallback } from 'react';
import TabCompany from '../admin/TabCompany';
import { useParams } from 'react-router-dom';
import HeaderCompany from '../common/HeaderCompany';
import useApiGetData from '../common/useApiGetData';
import WaitIconInline from '../common/WaitIconInline';
import Utils from '../common/Utils';
import MessageInline from '../common/MessageInline';
import MyTable from '../common/MyTable';
import Modal from '../common/Modal';

function DialogUpdateThreashold(props) {
    const d = props.appliance;
    const inputProps = { type: 'number', min: '1', max: '9999', className: 'form-control', maxLength: '8', autoFocus: true };
    return (
        <>
            <form>
                <div className="form-group row">
                    <label htmlFor="fieldUnitName" className="form-label col-form-label col-sm-8">
                        Threshold for {d.name}:
                    </label>
                    <div className="col-sm-4">
                        <input {...inputProps} value={d.leakThres} onChange={(e) => Utils.setState(props, 'leakThres', e, 1)} />
                    </div>
                </div>
            </form>
            <MessageInline msg={props.msg} setMsg={props.setMsg} />
        </>
    );
}

function CompanyThresholdDetail() {
    const { mId } = useParams();
    const shouldUpdate = Utils.useShouldUpdate();
    const [company, setCompany] = useState({});
    const [loading, setLoading] = useState(true);
    const [msg, setMsg] = useState('');
    const [modalShown, toggleModal] = useState(false);
    const [clickedItem, setClickedItem] = useState({ _id: 0, name: '', leakThres: '' });

    const CacheKey = 'CompanyAppDetail' + mId;

    useApiGetData('company-app', CacheKey, setCompany, null, { param: { mId } }, setLoading);

    const onRowClicked = useCallback(
        (e) => {
            const item = Utils.getRowData(e, company.appliances);
            if (item) {
                setClickedItem(item);
                toggleModal(true);
            }
        },
        [company.appliances]
    );

    const onUpdateThreshold = useCallback(() => {
        if (!clickedItem || isNaN(clickedItem.leakThres) || clickedItem.leakThres < 1) setMsg('Please enter the threshold');
        else {
            let comp = { ...company };
            let appliances = comp.appliances.map((e) => (e._id !== clickedItem._id ? e : clickedItem));
            Utils.apiPostData(
                'company-app',
                CacheKey,
                { mId, appliances },
                () => {
                    comp.appliances = appliances;
                    if (shouldUpdate.current) {
                        setMsg('');
                        setCompany(comp);
                    }
                    return comp;
                },
                (err, errMsg) => setMsg(errMsg || 'Failed to save data')
            );
        }
        return true;
    }, [CacheKey, clickedItem, company, mId, shouldUpdate]);

    const tableColumns = [
        { Header: '#', accessor: 'rowIndex', Cell: (props) => props.row.index + 1, className: 'text-center', headerClassName: 'text-center' },
        { Header: 'Appliances', accessor: 'name' },
        { Header: 'Leak Threshold', accessor: 'leakThres' },
    ];

    return loading ? (
        <WaitIconInline />
    ) : (
        <>
            <div className="container-fluid">
                <HeaderCompany name={company.name} address={Utils.formatAddress(company)} />
                <MessageInline msg={msg} setMsg={setMsg} />
                <MyTable columns={tableColumns} data={company.appliances} defaultPageSize={10} rowClassName={'clickable-row'} onRowClicked={onRowClicked} />
            </div>
            <Modal title="Set Threshold" show={modalShown} toggle={toggleModal} onOk={onUpdateThreshold} lbCancel={'Cancel'} lbOk={'Submit'}>
                <DialogUpdateThreashold msg={msg} setMsg={setMsg} appliance={clickedItem} setState={setClickedItem} />
            </Modal>
        </>
    );
}

export default function CompanyThreshold(props) {
    let newProps = Utils.cloneObject(props, 'activeIdx', 'render');
    return <TabCompany {...newProps} activeIdx={2} render={(props) => <CompanyThresholdDetail {...props} />} />;
}
