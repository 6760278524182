import React, { useState, useCallback, useContext } from 'react';
import HeaderUnit from '../common/HeaderUnit';
import TabBuildingUnit from '../users/TabBuildingUnit';
import useApiGetData from '../common/useApiGetData';
import WaitIconInline from '../common/WaitIconInline';
import Utils from '../common/Utils';
import MessageInline from '../common/MessageInline';
import MyTable from '../common/MyTable';
import Modal from '../common/Modal';
import DialogConfigSensor from '../common/DialogConfigSensor';
import useGetBuildingById, { defaultBuilding } from '../common/GetBuildingById';
import useGetUnitById, { defaultUnit } from '../common/GetUnitById';
import { Redirect, useParams } from 'react-router-dom';
import { ContextFilterSettings } from '../common/ContextFilterSettings';

function BuildingUnitSensorDetail() {
    const [sensors, setSensors] = useState([]);
    const [selectedSensors, setSelectedSensors] = useState([]);
    const [errMsg, setErrMsg] = useState('');
    const [bd, setBuilding] = useState(defaultBuilding);
    const [unit, setUnit] = useState(defaultUnit);
    const [loading, setLoading] = useState(true);
    const [modalShown, toggleModal] = useState(false);
    const [msg, setMsg] = useState('');
    const { bId, uId } = useParams();
    const badId = Utils.isBadId(bId);
    const [dlgValue, setDlgValue] = useState({ ids: [], interval: 0, useGsm: false });
    const shouldUpdate = Utils.useShouldUpdate();
    const filterSettings = useContext(ContextFilterSettings);

    useGetBuildingById(bId, setBuilding, badId);
    useGetUnitById(uId, setUnit, badId);

    const cacheKey = 'SensorList' + bId + 'u' + uId;
    useApiGetData(bId && uId && 'sensor', cacheKey, setSensors, null, { param: { uId } }, setLoading);

    const showSensorConfig = useCallback(() => {
        if (selectedSensors.length < 1) setErrMsg('Please select at least 1 sensor');
        else {
            const useGsm = selectedSensors[0].useGsm ? true : false;
            let dv = { ids: selectedSensors.map((e) => e.sn), useGsm, interval: selectedSensors[0].cfg.smInterval };
            setDlgValue(dv);
            toggleModal(true);
        }
    }, [selectedSensors]);

    const configSensor = useCallback(() => {
        if (dlgValue.useGsm && selectedSensors.length) dlgValue.interval = selectedSensors[0].gsmInterval;
        if (dlgValue.ids.length < 1 || isNaN(dlgValue.interval) || dlgValue.interval < 1) {
            setMsg('Invalid submit interval');
            return false;
        }
        const data = { ...dlgValue };

        Utils.apiPostData(
            'sensor-set-lsubmit',
            cacheKey,
            data,
            () => {
                const newData = sensors.map((s) => {
                    if (data.ids.indexOf(s.sn) >= 0) {
                        s.cfg.smInterval = data.interval;
                        s.useGsm = data.useGsm;
                        if (data.useGsm) s.lsmInterval = data.interval;
                        else s.gsmInterval = data.interval;
                    }
                    return s;
                });
                if (shouldUpdate.current) {
                    setSensors(newData);
                    setMsg('');
                    setErrMsg('');
                    toggleModal(false);
                }
                return newData;
            },
            (err, errMsg) => {
                if (shouldUpdate.current) setMsg(errMsg || 'Error while saving data');
            }
        );

        return false;
    }, [cacheKey, dlgValue, selectedSensors, sensors, shouldUpdate]);

    if (badId) return <Redirect to={'/building'} />;

    let tableColumns = [
        { Header: 'SN', accessor: 'sn' },
        { Header: 'Status', accessor: 'status', Cell: (props) => Utils.formatSensorStatus(props.cell.value) },
        { Header: 'Location', accessor: 'location', Cell: (props) => Utils.formatSensorLocation(props.cell.row.original) },
        { Header: 'Installed', accessor: 'setupDate', Cell: (props) => Utils.formatDate(props.cell.value) },
        { Header: 'Gallons', accessor: 'gallons', Cell: (props) => Utils.formatFloat(props.cell.value || 0) },
    ];

    let showCol = Utils.showSensorDetail(filterSettings);
    if (showCol) tableColumns = tableColumns.concat([{ Header: 'Battery', accessor: 'battery', Cell: (props) => Math.floor(props.cell.value + 0.5) + '%' }]);
    tableColumns = tableColumns.concat([{ Header: 'Alerts', accessor: 'alerts', Cell: (props) => Utils.formatAlertNumDiv(props.cell.row.original, false) }]);
    if (showCol) tableColumns = tableColumns.concat([{ Header: 'Submit Interval', accessor: 'gsmInterval', Cell: (props) => Utils.formatSensorSmInterval(props.cell.row.original) }]);

    return loading ? (
        <WaitIconInline />
    ) : (
        <>
            <div className="container-fluid table-margin">
                <HeaderUnit name={unit.name} address={Utils.formatAddress(bd)} tenant={unit.tenant} addSensorButton={showSensorConfig} />
                <div className="table-responsive-sm">
                    <MessageInline msg={errMsg} setMsg={setErrMsg} />
                    <MyTable columns={tableColumns} data={sensors} defaultPageSize={10} setSelectedRows={setSelectedSensors} />
                </div>
            </div>
            <Modal title="Configure Sensor" show={modalShown} toggle={toggleModal} lbCancel={'Cancel'} lbOk={'Submit'} onOk={configSensor}>
                <DialogConfigSensor msg={msg} setMsg={setMsg} bId={bId} state={dlgValue} setState={setDlgValue} />
            </Modal>
        </>
    );
}

/*
/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Old version which show the addSensor dialog

import React, { useState, useCallback, useEffect } from 'react';
import HeaderUnit from '../common/HeaderUnit';
import TabBuildingUnit from '../users/TabBuildingUnit';
import Utils, { useShouldUpdate } from '../common/Utils';
import useGetBuildingById, { defaultBuilding } from '../common/GetBuildingById';
import useGetUnitById, { defaultUnit } from '../common/GetUnitById';
import useApiGetData from '../common/useApiGetData';
import { Redirect, useParams } from 'react-router-dom';
import WaitIconInline from '../common/WaitIconInline';
import Modal from '../common/Modal';
import DialogAddSensor from '../common/DialogAddSensor';

const defaultSensor = {
  sn: 0,
  building: null,
  unit: null,
  room: null,
  waterType: null,
  appliance: null,
};


function BuildingUnitSensorDetail() {
  const [state, setState] = useState({ sensors: [] });
  const [bd, setBuilding] = useState(defaultBuilding);
  const [unit, setUnit] = useState(defaultUnit);
  const [loading, setLoading] = useState(true);
  const [modalShown, toggleModal] = useState(false);
  const [msg, setMsg] = useState('');
  const [sensor, setSensor] = useState(defaultSensor);
  const { bId, uId } = useParams();
  const badId = Utils.isBadId(bId);

  const shouldUpdate = useShouldUpdate();

  useGetBuildingById(bId, setBuilding, badId);
  useGetUnitById(uId, setUnit, badId);

  const cacheKey = 'SensorList' + bId + 'u' + uId;
  useApiGetData('sensor', cacheKey, setState, data => ({ sensors: data }), { param: { uId }, skip: badId }, setLoading);

  useEffect(() => {
    let no = { bd };
    if (unit) no.unit = { value: unit._id, label: unit.name };
    setSensor(m => ({ ...m, ...no }));
  }, [bd, unit]);

  const addSensor = useCallback(() => {
    let errText = null;
    if (!sensor.unit) errText = 'Cannot get active unit ID';
    else if (!sensor.sn || isNaN(sensor.sn)) errText = 'Bad sensor SN';
    else if (!sensor.room) errText = 'Please select a room';
    else if (!sensor.waterType) errText = 'Please select water type';
    else if (!sensor.appliance) errText = 'Please select applicance';
    if (errText) {
      setMsg(errText);
      return false;
    }

    const ns = {
      _id: sensor.sn,
      sn: sensor.sn,
      status: 0,
      rId: sensor.room.value,
      rName: sensor.room.label,
      tId: sensor.waterType.value,
      tName: sensor.waterType.label,
      appId: sensor.appliance.value,
      appName: sensor.appliance.label,
      alertContactId: 1,
      uId: sensor.unit._id,
      bId,
    };

    Utils.apiPostData(
      'add-sensor',
      cacheKey,
      ns,
      data => {
        console.log('sensor: ', data);
        if (isNaN(data.sn)) data.sn = ns.sn;
        if (!isNaN(data.uId)) data.uId = parseInt(data.uId);
        const newData = { sensors: state.sensors.concat([data]) };
        if (shouldUpdate.current) {
          setState(newData);
          setMsg('');
          if (modalShown) toggleModal(false);
        }
        return newData;
      },
      (err, errMsg) => {
        if (shouldUpdate.current) setMsg(errMsg || 'Error while saving data');
      }
    );

    return false;
  }, [bId, cacheKey, modalShown, sensor.appliance, sensor.room, sensor.sn, sensor.unit, sensor.waterType, shouldUpdate, state.sensors]);

  if (badId) return <Redirect to={'/building'} />;

  return loading ? (
    <WaitIconInline />
  ) : (
    <>
      <div className="container-fluid table-margin">
        <HeaderUnit name={unit.name} address={Utils.formatAddress(bd)} tenant={unit.tenant} addSensorButton={() => toggleModal(m => !m)} />
        <div className="table-responsive-sm">
          <table className="tab-table table table-sm table-striped table-bordered table-hover">
            <thead className="thead-dark">
              <tr>
                <th scope="col" className="text-center">
                  #
                </th>
                <th scope="col">Sensor ID</th>
                <th scope="col">Status</th>
                <th scope="col">Location</th>
                <th scope="col">Installed</th>
                <th scope="col">Gallons</th>
                <th scope="col">Battery</th>
                <th scope="col">Alerts</th>
                <th scope="col">Submit Interval</th>
              </tr>
            </thead>
            <tbody>
              {state.sensors.map(e => (
                <tr key={e.sn}>
                  <td className="text-center">
                    <input type="checkbox" id={'chk' + e.sn} />
                  </td>
                  <td>{e.sn}</td>
                  <td>{Utils.formatSensorStatus(e)}</td>
                  <td>
                    {e.rName}, {e.appName}, {e.tName}
                  </td>
                  <td>{new Date(e.installedDate).toLocaleDateString()}</td>
                  <td>{Utils.formatFloat(e.gallons)}</td>
                  <td>{Math.floor(e.battery + 0.5)}%</td>
                  <td style={Utils.alertNumStyle(e.oAlerts + e.cAlerts)}>{Utils.formatAlertNum(e.oAlerts + e.cAlerts, false)}</td>
                  <td>Default</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <Modal title="Add Sensor" show={modalShown} toggle={toggleModal} lbCancel={'Cancel'} lbOk={'Submit'} onOk={addSensor}>
        <DialogAddSensor msg={msg} setMsg={setMsg} bId={bId} sensor={sensor} setState={setSensor} />
      </Modal>
    </>
  );
}
/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
*/

export default function BuildingUnitSensor(props) {
    let newProps = Utils.cloneObject(props, 'activeIdx', 'render');
    return <TabBuildingUnit {...newProps} activeIdx={1} component={BuildingUnitSensorDetail} />;
}
