import React from 'react';
import Utils from '../common/Utils';
import MessageInline from '../common/MessageInline';

export default function DialogConfigSensor(props) {
    const v = props.state;
    const inputProps = { type: 'number', min: '1', max: '9999', className: 'form-control', maxLength: '8', autoFocus: true };

    function onCheck(e) {
        const t = e && e.target && e.target.checked ? true : false;
        if (props.setState) props.setState((m) => ({ ...m, useGsm: t }));
    }

    return (
        <>
            <form>
                <div className="form-group row">
                    <label className="form-label col-form-label col-sm-6">Submit Interval:</label>
                    <div className="col-sm-6">
                        <input type="number" {...inputProps} value={v.interval} disabled={v.useGsm} onChange={(e) => Utils.setState(props, 'interval', e, 1)} />
                    </div>
                </div>
                <div className="form-check">
                    <input type="checkbox" className="form-check-input" id="useDefaultSmInterval" value={true} checked={v.useGsm} onChange={onCheck} />
                    <label className="form-check-label" htmlFor="useDefaultSmInterval">
                        Use System Default
                    </label>
                </div>
            </form>
            <MessageInline msg={props.msg} setMsg={props.setMsg} />
        </>
    );
}
