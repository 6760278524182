import React from 'react';
import { Link, useParams } from 'react-router-dom';
import Utils from '../common/Utils';

export default function Tab(props) {
    const { bId, uId } = useParams();
    const { mId } = useParams();

    let alertCnt = parseInt(props.alertCnt);
    if (isNaN(alertCnt)) alertCnt = parseInt(localStorage.getItem('alertCnt'));
    if (isNaN(alertCnt)) alertCnt = '?';
    else localStorage.setItem('alertCnt', alertCnt);

    let idUrl = '';
    if (!Utils.isBadId(bId)) idUrl += '/' + bId;
    if (!Utils.isBadId(uId)) idUrl += '/' + uId;
    if (!Utils.isBadId(mId)) idUrl += '/' + mId;

    const activeIdx = props.activeIdx || 0;
    const navType = props.nav ? 'second-level-tab nav nav-pills' : 'nav nav-tabs';
    return (
        <ul className={navType} role="tablist">
            {props.items.map((e, idx) => (
                <li className="nav-item" key={idx}>
                    <Link to={e.url + (e.id ? idUrl : '')} className={idx === activeIdx ? 'nav-link active' : 'nav-link'}>
                        {e.title}
                        {e.alertCnt ? (
                            <div className="alert-number" style={{ display: 'inline-block' }}>
                                <span className="alert-number-data" id="alert_count">
                                    {alertCnt}
                                </span>
                            </div>
                        ) : null}
                    </Link>
                </li>
            ))}
        </ul>
    );

    /*
  <a className={e.active ? 'nav-link active' : 'nav-link'} href="#">{e.title}</a>
  
<ul class="nav nav-tabs">
  <li class="nav-item">
    <a class="nav-link active" href="#">Active</a>
  </li>
  <li class="nav-item">
    <a class="nav-link" href="#">Link</a>
  </li>
  <li class="nav-item">
    <a class="nav-link" href="#">Link</a>
  </li>
  <li class="nav-item">
    <a class="nav-link disabled" href="#">Disabled</a>
  </li>
</ul>
*/
}
