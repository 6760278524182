import React, { useState, useCallback } from 'react';
import HeaderTopAdmin from '../admin/HeaderTopAdmin';
import Header from '../common/Header';
import MessageInline from '../common/MessageInline';
import Utils from '../common/Utils';
import useApiGetData from '../common/useApiGetData';
import WaitIconInline from '../common/WaitIconInline';
import Select from 'react-select';
import useMgmtBuildingList from './useMgmtBuildingList';

function DialogDownloadForm(props) {
    const inputProps = { type: 'date', className: 'form-control' };
    const companys = Array.isArray(props.companys) ? props.companys : [];
    const [buildings, onMgmtCompanyChanged] = useMgmtBuildingList(props);

    const onDateChange = useCallback((e, fn) => {
        const d = e && e.target ? Date.parse(e.target.value) : NaN;
        return isNaN(d) || d < 1000 ? fn(null) : fn(new Date(d));
    }, []);

    const fromDate = Utils.formatDateInput(props.fromDate);
    const toDate = Utils.formatDateInput(props.toDate);

    return (
        <>
            <div className='container-fluid'>
                <div>&nbsp;</div>
                <h4>Download Filters</h4>
                <div>&nbsp;</div>
                <form>
                    <div className='form-group row'>
                        <label className='form-label col-form-label col-sm-3'>From date:</label>
                        <div className='col-sm-9'>
                            <input {...inputProps} value={fromDate} onChange={e => onDateChange(e, props.setFromDate)} />
                        </div>
                    </div>
                    <div className='form-group row'>
                        <label className='form-label col-form-label col-sm-3'>To date:</label>
                        <div className='col-sm-9'>
                            <input {...inputProps} value={toDate} onChange={e => onDateChange(e, props.setToDate)} />
                        </div>
                    </div>
                    <div className='form-group row'>
                        <label className='form-label col-form-label col-sm-3'>Company:</label>
                        <div className='col-sm-9'>
                            <Select classNamePrefix='react-select-focus' isClearable={true} options={companys} value={props.selCompany} onChange={onMgmtCompanyChanged} />
                        </div>
                    </div>
                    <div className='form-group row'>
                        <label className='form-label col-form-label col-sm-3'>Building:</label>
                        <div className='col-sm-9'>
                            <Select classNamePrefix='react-select-focus' isClearable={true} options={buildings} value={props.selBuilding} onChange={props.setSelBuilding} />
                        </div>
                    </div>
                    <div className='form-group row'>
                        <div className='col-sm-12'>
                            <button type='button' className='btn btn-primary' onClick={props.onDownloadSamples}>
                                Samples CSV
                            </button>
                            &nbsp; &nbsp;
                            <button type='button' className='btn btn-primary' onClick={props.onDownloadAlerts}>
                                Alerts CSV
                            </button>
                        </div>
                    </div>
                </form>
                <MessageInline msg={props.msg} setMsg={props.setMsg} />
            </div>
        </>
    );
}

function DownloadDetail() {
    const getDefaultDate = useCallback(isFirstDay => {
        const d = new Date();
        return isFirstDay ? new Date(d.getFullYear(), d.getMonth() - 1, 1) : new Date(d.getFullYear(), d.getMonth(), 0, 23, 59, 59);
    }, []);

    const [loading, setLoading] = useState(true);

    const [fromDate, setFromDate] = useState(() => getDefaultDate(true));
    const [toDate, setToDate] = useState(() => getDefaultDate(false));
    const [selCompany, setSelCompany] = useState(null);
    const [selBuilding, setSelBuilding] = useState(null);

    const [companys, setCompanys] = useState([]);
    useApiGetData('company', 'CompanyList', setCompanys, data => data.map(e => ({ label: e.name, value: e._id })), null, setLoading);

    const [msg, setMsg] = useState('');
    const [infoMsg, setInfoMsg] = useState('');

    const onDownloadValidation = useCallback(() => {
        if (fromDate && toDate && fromDate.getTime() > toDate.getTime()) {
            setMsg('Invalid date range');
            return false;
        }
        return true;
    }, [fromDate, toDate]);

    const getEpocMs = function (d, last = 0) {
        const dd = new Date(d.getFullYear(), d.getMonth(), d.getDate(), 23 * last, 59 * last, 59 * last);
        return dd.getTime();
    };

    const downloadHelper = useCallback(
        linkName => {
            const url = (Utils.isDebug() ? 'http://localhost:8000' : '') + '/api/' + linkName + '?';
            let params = [];
            if (fromDate) params.push('fromDate=' + getEpocMs(fromDate));
            if (toDate) params.push('toDate=' + getEpocMs(toDate, 1));
            if (selCompany && !isNaN(selCompany.value)) params.push('mId=' + selCompany.value);
            if (selBuilding && !isNaN(selBuilding.value)) params.push('bId=' + selBuilding.value);
            const d = new Date();
            params.push('tz=' + d.getTimezoneOffset() * 60 * 1000);
            window.location = url + params.join('&');
        },
        [fromDate, selBuilding, selCompany, toDate]
    );

    const onDownloadSamplesCSV = useCallback(() => {
        if (!onDownloadValidation()) return;
        downloadHelper('download-samples');
    }, [downloadHelper, onDownloadValidation]);

    const onDownloadAlertCSV = useCallback(() => {
        if (!onDownloadValidation()) return;
        downloadHelper('download-alerts');
    }, [downloadHelper, onDownloadValidation]);

    return loading ? (
        <WaitIconInline />
    ) : (
        <>
            <DialogDownloadForm
                msg={msg}
                setMsg={setMsg}
                onDownloadSamples={onDownloadSamplesCSV}
                onDownloadAlerts={onDownloadAlertCSV}
                fromDate={fromDate}
                toDate={toDate}
                setFromDate={setFromDate}
                setToDate={setToDate}
                companys={companys}
                selCompany={selCompany}
                selBuilding={selBuilding}
                setSelCompany={setSelCompany}
                setSelBuilding={setSelBuilding}
            />
            <MessageInline msg={infoMsg} setMsg={setInfoMsg} info={true} />
        </>
    );
}

export default function Download() {
    return (
        <>
            <HeaderTopAdmin />
            <Header menu={'menuAdmin'} activeIdx={4} render={props => <DownloadDetail {...props} />} />
        </>
    );
}
