import React, { useState } from 'react';
import TabBuildingUnit from '../users/TabBuildingUnit';
import Utils from '../common/Utils';
import CounterCard from '../common/CounterCard';
import HeaderUnit from '../common/HeaderUnit';
import useGetBuildingById, { defaultBuilding } from '../common/GetBuildingById';
import useGetUnitById, { defaultUnit } from '../common/GetUnitById';
import { Redirect, useParams } from 'react-router-dom';

function BuildingUnitViewDetail() {
    const [bd, setBuilding] = useState(defaultBuilding);
    const [unit, setUnit] = useState(defaultUnit);

    const { bId, uId } = useParams();
    const badId = Utils.isBadId(bId);

    useGetBuildingById(bId, setBuilding, badId);
    useGetUnitById(uId, setUnit, badId);

    if (badId) return <Redirect to={'/building'} />;

    return (
        <div className="container-fluid">
            <HeaderUnit name={unit.name} address={Utils.formatAddress(bd)} tenant={unit.tenant} />
            <div className="row">
                <div className="col no-margin">
                    <CounterCard title="Alerts" number={unit.cAlerts + unit.oAlerts} alertColor={true} />
                </div>
                <div className="col no-margin">
                    <CounterCard title="MicroMeters" number={unit.sensors.length} />
                </div>
                <div className="col no-margin">
                    <CounterCard title="Gallons Used" number={Utils.formatFloat(unit.gallons)} />
                </div>
            </div>
            <div className="row">
                <div className="col no-margin">
                    <CounterCard title="# Tenants" number={unit.tenantCnt} />
                </div>
                <div className="col no-margin">
                    <CounterCard title="Sq Foot" number="-" />
                </div>
                <div className="col no-margin">
                    <CounterCard title="Graph" number="-" />
                </div>
            </div>
        </div>
    );
}

export default function BuildingUnitView(props) {
    let newProps = Utils.cloneObject(props, 'activeIdx', 'render');
    return <TabBuildingUnit {...newProps} activeIdx={0} component={BuildingUnitViewDetail} />;
}
