import React from 'react';
import Utils from '../common/Utils';
import MessageInline from '../common/MessageInline';

export default function DialogCloseAlert(props) {
    const a = props.alert;
    const aid = (a && a._id) || '';
    const atm = (a && Utils.formatDateTime(a.createdTime)) || '';
    const abd = (a && a.street) || '';

    const sendNote = (e) => {
        const note = (e && e.target.value) || '';
        if (props.setNote) props.setNote(note);
    };

    return (
        <>
            <div style={{ marginBottom: '1rem' }}>
                <h5>Please confirm closing of the following alert:</h5>
                <div>- Alert ID: {aid}</div>
                <div>- Alert date: {atm}</div>
                <div>- Building: {abd}</div>
            </div>
            <form>
                <div className="form-group row">
                    <label className="form-label col-form-label col-sm-2">Note:</label>
                    <div className="col-sm-10">
                        <input maxLength="256" type="text" className="form-control" value={props.note || ''} onChange={sendNote} />
                    </div>
                </div>
            </form>
            <MessageInline msg={props.msg} setMsg={props.setMsg} />
        </>
    );
}
