import { useEffect, useContext } from 'react';
import axios from 'axios';
import { AppCache } from '../common/MyCache';
import Utils from '../common/Utils';
import { ContextFilterSettings } from '../common/ContextFilterSettings';

let ivId = 0;

export default function useApiGetData(apiName, cacheKey, setStateFn, preProcessDataFn, options, setLoadingFn) {
    const filterSettings = useContext(ContextFilterSettings);

    const updateList = options && Array.isArray(options.updateList) ? options.updateList : [];
    useEffect(() => {
        let shouldUpdate = true;
        const rvl = () => (shouldUpdate = false);

        if (!apiName || (options && options.skip)) return rvl;

        if (setLoadingFn) setLoadingFn(true);
        Utils.addFilterKey(apiName, cacheKey);

        // get from cache
        const cacheData = AppCache.get(cacheKey);
        if (cacheData !== null) {
            if (setStateFn) setStateFn(cacheData);
            if (setLoadingFn) setLoadingFn(false);
            return rvl;
        }

        // no cache, load from api
        const url = Utils.prepareUrl(apiName);
        const pm = axios.get(url, options && options.param ? { params: options.param } : undefined);
        pm.then((res) => {
            let rvl = Utils.fixNumberString(res.data);
            if (preProcessDataFn) {
                const t = preProcessDataFn(rvl);
                if (t !== undefined) rvl = t;
            }
            if (setStateFn && shouldUpdate) setStateFn(rvl);

            AppCache.add(cacheKey, rvl);
            if (setLoadingFn && shouldUpdate) setLoadingFn(false);
        }).catch((err) => {
            const er = Utils.formatError(err);
            if (options && options.fail) options.fail(er, err.errMsg);
            if (setLoadingFn && shouldUpdate) setLoadingFn(false);
            console.log('RestApi Exception at ' + apiName + ': ' + err);
            if (!ivId && (err.code === 401 || err.code === 403))
                ivId = setInterval(() => {
                    Utils.deleteAllCookies();
                    AppCache.clear();
                    ivId = 0;
                    window.location = '/login';
                }, 1000);
        });

        return rvl;

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterSettings, ...updateList]);
}
