import React from 'react';
import Utils from '../common/Utils';

export default function HeaderBuilding(props) {
    const inline = { display: 'inline' };
    const title = props.title ? props.title + ': ' : 'Building manager: ';
    const pic = Utils.base() + (props.pic ? 'img/building/' + props.pic : 'img/building.png');
    return (
        <div className="row">
            <div className="col align-self-center">
                <h5>{props.address}</h5>
                <div>
                    {title}
                    <h6 style={inline}>{props.manager}</h6>
                </div>
            </div>
            <div className="col-sm-auto">
                <img src={pic} className="img-thumbnail" alt="{props.manager}" width="96" height="96" />
            </div>
        </div>
    );
}
