import React, { useState, useCallback } from 'react';
import { Redirect } from 'react-router-dom';
import Utils, { useShouldUpdate } from '../common/Utils';
import HeaderTop from '../common/HeaderTop';
import FooterBottom from '../common/FooterBottom';
import Header from '../common/Header';
import HeaderSearch from '../common/HeaderSearch';
import Modal from '../common/Modal';
import useApiGetData from '../common/useApiGetData';
import { addBuildingToCache } from '../common/GetBuildingById';
import WaitIconInline from '../common/WaitIconInline';
import DialogAddBuilding from '../common/DialogAddBuilding';

const menu = [{ title: 'Address' }, { title: 'Manager' }, { title: 'Owner' }];

const defaultBuilding = {
    street: '',
    city: null,
    state: null,
    manager: null,
    owner: 0,
    pic: 'noname.png',
};

function BuildingDetail() {
    const [modalShown, toggleModal] = useState(false);
    const [state, setState] = useState({ buildings: [] });
    const [clickedItem, setClickedItem] = useState(null);
    const [loading, setLoading] = useState(true);
    const [msg, setMsg] = useState(null);

    const [building, setBuilding] = useState(defaultBuilding);

    const onRowClicked = useCallback(
        (e) => {
            const item = Utils.getRowData(e, state.buildings);
            if (item) {
                setClickedItem(item);
                toggleModal((m) => !m);
            }
        },
        [state.buildings]
    );

    const shouldUpdate = useShouldUpdate();
    const cacheKey = 'BuildingDetailList';

    const addBuilding = useCallback(() => {
        let errText = null;
        if (!building || !building.street) errText = 'Please enter building street';
        else if (!building.state) errText = 'Please select a state';
        else if (!building.city) errText = 'Please select a city';
        else if (!building.manager) errText = 'Please select a manager';

        if (errText) {
            setMsg(errText);
            return false;
        }

        const bd = {
            street: building.street,
            city: building.city.label,
            cityId: building.city.value,
            state: building.state.label,
            stateId: building.state.value,
            manager: building.manager.value,
            pic: building.pic,
            countryId: 1,
            country: 'USA',
            units: [],
        };
        const bdMore = { mngFirstName: building.manager.fn, mngLastName: building.manager.ln, sensorCnt: 0, sensors: [], units: [] };
        Utils.apiPostData(
            'add-building',
            cacheKey,
            bd,
            (data) => {
                const newData = { buildings: state.buildings.concat([{ ...data, ...bdMore }]) };
                if (shouldUpdate.current) {
                    setState(newData);
                    setMsg('');
                    if (modalShown) toggleModal(false);
                }
                return newData;
            },
            (err, errMsg) => {
                if (shouldUpdate.current) setMsg(errMsg || 'Error while saving data');
            }
        );

        return false;
    }, [building, modalShown, shouldUpdate, state.buildings]);

    useApiGetData(
        'building',
        cacheKey,
        setState,
        (data) => ({
            buildings: data.map((e) => {
                e.sensorCnt = Array.isArray(e.sensors) ? (e.sensorCnt = [].concat.apply([], e.sensors).length) : 0;
                return e;
            }),
        }),
        null,
        setLoading
    );

    if (clickedItem) {
        addBuildingToCache(clickedItem);
        return <Redirect to={'/building-view/' + clickedItem._id} />;
    }

    const addBuildingLabel = Utils.isAdminOrManager() ? 'Add Building' : null;

    return loading ? (
        <WaitIconInline />
    ) : (
        <>
            <div className="container-fluid table-margin">
                <HeaderSearch leftTitle={addBuildingLabel} menuItems={menu} leftBtnClick={() => toggleModal((m) => !m)} />
                <div className="table-responsive-sm">
                    <table className="tab-table table table-sm table-striped table-bordered table-hover">
                        <thead className="thead-dark">
                            <tr>
                                <th scope="col">Street</th>
                                <th scope="col">City</th>
                                <th scope="col">State</th>
                                <th scope="col">MicroMeters</th>
                                <th scope="col">Alerts</th>
                                <th scope="col">Manager</th>
                            </tr>
                        </thead>
                        <tbody>
                            {state.buildings.map((e) => (
                                <tr key={e._id} data-row-idx={e._id} onClick={onRowClicked} className="clickable-row">
                                    <td>{e.street}</td>
                                    <td>{e.city}</td>
                                    <td>{e.state}</td>
                                    <td>{e.sensorCnt}</td>
                                    <td style={Utils.alertNumStyle(e.oAlerts + e.cAlerts)}>{Utils.formatAlertNum(e.oAlerts + e.cAlerts, false)}</td>
                                    <td>{e.mngFirstName + ' ' + e.mngLastName}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
            <Modal title="Add Building" show={modalShown} toggle={toggleModal} onOk={addBuilding} lbCancel={'Cancel'} lbOk={'Submit'}>
                <DialogAddBuilding msg={msg} setMsg={setMsg} building={building} setState={setBuilding} />
            </Modal>
        </>
    );
}

export default function Building() {
    return (
        <>
            <HeaderTop />
            <Header activeIdx={2} render={(props) => <BuildingDetail {...props} />} />
            <FooterBottom />
        </>
    );
}
