import React, { useState, useCallback } from 'react';
import HeaderTopAdmin from '../admin/HeaderTopAdmin';
import Header from '../common/Header';
import useApiGetData from '../common/useApiGetData';
import WaitIconInline from '../common/WaitIconInline';
import Utils, { useShouldUpdate } from '../common/Utils';
import Select from 'react-select';
import { AppCache } from '../common/MyCache';
import MessageInline from '../common/MessageInline';
import MyTable from '../common/MyTable';
import Modal from '../common/Modal';
import useMgmtBuildingList from './useMgmtBuildingList';

function DialogSetBuilding(props) {
    const companys = Array.isArray(props.companys) ? props.companys : [];
    const [buildings, onMgmtCompanyChanged] = useMgmtBuildingList(props);

    return (
        <>
            <form>
                <div className='form-group row'>
                    <label className='form-label col-form-label col-sm-3'>Company:</label>
                    <div className='col-sm-9'>
                        <Select classNamePrefix='react-select-focus' isClearable={true} options={companys} value={props.selCompany} onChange={onMgmtCompanyChanged} />
                    </div>
                </div>
                <div className='form-group row'>
                    <label className='form-label col-form-label col-sm-3'>Building:</label>
                    <div className='col-sm-9'>
                        <Select classNamePrefix='react-select-focus' isClearable={true} options={buildings} value={props.selBuilding} onChange={props.setSelBuilding} />
                    </div>
                </div>
            </form>
            <MessageInline msg={props.msg} setMsg={props.setMsg} />
        </>
    );
}
function GatewayDetail() {
    const [loading, setLoading] = useState(true);
    const [setBdShown, toggleSetSetBd] = useState(false);
    const [gateways, setGateways] = useState([]);
    const [msg, setMsg] = useState('');
    const [errMsg, setErrMsg] = useState('');
    const [selectedGateways, setSelectedGateways] = useState([]);
    const [companys, setCompanys] = useState([]);
    const shouldUpdate = useShouldUpdate();
    const [selCompany, setSelCompany] = useState(null);
    const [selBuilding, setSelBuilding] = useState(null);

    const CacheKeyGatewayInfo = 'CacheKey_gateways';

    useApiGetData(
        'gateways',
        CacheKeyGatewayInfo,
        setGateways,
        data => {
            if (data && Array.isArray(data.gateways)) {
                const stopThreshold = isNaN(data.stopThreshold) ? 1000 * 60 * 60 * 6 : parseInt(data.stopThreshold);
                const nn = Date.now() - stopThreshold;
                return data.gateways.map(e => {
                    e.stopped = !isNaN(e.accessed) && e.accessed < nn;
                    return e;
                });
            } else return [];
        },
        null,
        setLoading
    );
    useApiGetData('company', 'CompanyList', setCompanys, data => data.map(e => ({ label: e.name, value: e._id })), null, setLoading);

    const LastSubmitTime = function (cell) {
        const redStyle = cell && cell.row && cell.row.original && cell.row.original.stopped ? { color: 'red' } : null;
        return <div style={redStyle}>{Utils.formatDateTime(cell ? cell.cell.value : null)}</div>;
    };

    const setBuilding = useCallback(() => {
        if (!selCompany || !selBuilding || !Array.isArray(selectedGateways) || !selectedGateways.length || !Array.isArray(gateways)) {
            setErrMsg('Please fill the form');
            return false;
        }

        const bName = selBuilding.label;
        const bId = selBuilding.value;
        const gIdList = selectedGateways.map(e => e._id);
        Utils.apiPostData(
            'gateways',
            null,
            { gIdList, bId, bName },
            () => {
                const newGateways = gateways.map(e => {
                    const gw = selectedGateways.find(g => g._id === e._id);
                    if (gw) {
                        e.bName = bName;
                        e.bId = bId;
                    }
                    return e;
                });
                if (shouldUpdate.current) setGateways(newGateways);
                AppCache.set(CacheKeyGatewayInfo, newGateways);
            },
            (err, em) => {
                if (shouldUpdate.current) setMsg(em || 'Error while saving data');
            }
        );
        return true;
    }, [gateways, selBuilding, selCompany, selectedGateways, shouldUpdate]);

    const showSetBuildingDlg = useCallback(() => {
        const cnt = selectedGateways.length;
        if (cnt) toggleSetSetBd(true);
        else setMsg('Please select at least 1 Gateway');
    }, [selectedGateways.length]);

    const tableColumns = [
        { Header: 'ID', accessor: 'name' },
        {
            Header: 'Building',
            accessor: 'bName',
        },
        {
            Header: 'Created',
            accessor: 'created',
            Cell: props => Utils.formatDateTime(props.cell.value),
        },
        {
            Header: 'Last Submit',
            accessor: 'accessed',
            Cell: props => LastSubmitTime(props),
        },
    ];

    const btnStyle = { marginTop: '1rem', marginBottom: '1rem' };

    return loading ? (
        <WaitIconInline />
    ) : (
        <>
            <div className='container-fluid'>
                <div style={btnStyle}>
                    <button className='btn btn-primary' onClick={showSetBuildingDlg}>
                        Set Building
                    </button>
                </div>
                <MessageInline msg={msg} setMsg={setMsg} />
                <div className='table-responsive-sm'>
                    <MyTable columns={tableColumns} data={gateways} defaultPageSize={10} setSelectedRows={setSelectedGateways} />
                </div>
            </div>
            <Modal title='Set Buildings' show={setBdShown} toggle={toggleSetSetBd} onOk={setBuilding} lbCancel={'Cancel'} lbOk={'Save'}>
                <DialogSetBuilding
                    msg={errMsg}
                    setMsg={setErrMsg}
                    setSelectedRows={setSelectedGateways}
                    companys={companys}
                    selCompany={selCompany}
                    setSelCompany={setSelCompany}
                    selBuilding={selBuilding}
                    setSelBuilding={setSelBuilding}
                />
            </Modal>
        </>
    );
}

export default function Gateway() {
    return (
        <>
            <HeaderTopAdmin />
            <Header menu={'menuAdmin'} activeIdx={2} render={props => <GatewayDetail {...props} />} />
        </>
    );
}
