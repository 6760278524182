export class MyCache {
    constructor(options) {
        if (!options) options = {}; // ttl: # of secconds to ageout
        this.ttl = isNaN(options.ttl) || options.ttl < 1 ? 15 * 60000 : options.ttl * 1000;
        this.check = isNaN(options.check) || options.check < 1 ? 1 * 60000 : options.check * 1000;
        this.cache = {};
        this.enableTtlCheck();
    }

    clear() {
        this.cache = {};
    }

    disableTtlCheck() {
        if (this.ttlCheckId) {
            clearInterval(this.ttlCheckId);
            this.ttlCheckId = 0;
        }
    }

    keys() {
        return Object.keys(this.cache);
    }

    catchLength() {
        return Object.keys(this.cache).length;
    }

    enableTtlCheck() {
        if (!this.ttlCheckId && this.catchLength()) {
            this.ttlCheckId = setInterval(() => this.checkExpiration(), this.check);
        }
    }

    checkExpiration() {
        const nn = Date.now();
        Object.keys(this.cache).forEach((key) => {
            let data = this.cache[key];
            if (data && !isNaN(data.ttl) && data.ttl < nn) delete this.cache[key];
        });
    }

    get(key) {
        if (!key) return null;
        const data = this.cache[key];
        if (!data || data.item === undefined || isNaN(data.ttl) || Date.now() < data.ttl) return null;
        return data.item;
    }

    add(key, val, itemTtl = 0) {
        if (!key) return;
        const ttl = Date.now() + (isNaN(itemTtl) ? this.ttl : itemTtl);
        delete this.cache[key];
        const data = { ttl, item: val };
        this.cache[key] = data;
    }

    set(key, val) {
        this.add(key, val);
    }

    remove(key) {
        if (key) delete this.cache[key];
    }
}

export const AppCache = new MyCache();
