import React, { useState } from 'react';
import HeaderTopAdmin from '../admin/HeaderTopAdmin';
import Header from '../common/Header';
import CounterCard from '../common/CounterCard';
import useApiGetData from '../common/useApiGetData';
import WaitIconInline from '../common/WaitIconInline';

function HomeAdminDetail() {
    const [loading, setLoading] = useState(true);
    const [state, setState] = useState({ sCnt: 0, asCnt: 0, mgmtCnt: 0 });

    useApiGetData('admin-home', 'AdminHomeDetail', setState, null, null, setLoading);

    return loading ? (
        <WaitIconInline />
    ) : (
        <div className="container-fluid">
            <div className="row">
                <div className="col no-margin">
                    <CounterCard title="Management Company" number={state.mgmtCnt} />
                </div>
                <div className="col no-margin">
                    <CounterCard title="Unassigned MicroMeters" number={state.sCnt - state.asCnt} />
                </div>
                <div className="col no-margin">
                    <CounterCard title="Assigned MicroMeters" number={state.asCnt} />
                </div>
            </div>
        </div>
    );
}

export default function HomeAdmin() {
    return (
        <>
            <HeaderTopAdmin />
            <Header menu={'menuAdmin'} activeIdx={0} component={HomeAdminDetail} />
        </>
    );
}
