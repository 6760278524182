import React from 'react';

const leftButton = {
    paddingRight: '0px',
};

const rightButton = {
    paddingLeft: '0px',
};

export default function HeaderSearch(props) {
    const leftButtonHtml = props.leftTitle ? (
        <div className="col-auto" style={leftButton}>
            <button className="btn btn-primary" onClick={props.leftBtnClick}>
                {props.leftTitle}
            </button>
        </div>
    ) : null;

    const rightTitle = props.rightTitle || 'Sort By';
    const menuItems = props.menuItems || [{ title: 'Admin' }, { title: 'Manager' }, { title: 'Owner' }];

    const menuStyle = {
        position: 'absolute',
        top: '0px',
        left: '0px',
        transform: 'translate3d(-70px, 38px, 0px)',
        willChange: 'transform',
    };
    const items = (
        <>
            {menuItems.map((item, itemIdx) => (
                <button className="dropdown-item" key={itemIdx} onClick={item.onClicked || ((e) => console.log('clicked: ' + e.target))}>
                    {item.title}
                </button>
            ))}
        </>
    );

    return (
        <div className="row text-center">
            {leftButtonHtml}
            <div className="col">
                <div className="form-group has-search">
                    <span className="fa fa-search form-control-feedback" />
                    <input type="text" className="form-control" placeholder="Search" />
                </div>
            </div>
            <div className="col-auto" style={rightButton}>
                <div className="btn-group">
                    <button
                        aria-haspopup="true"
                        aria-expanded="false"
                        data-toggle="dropdown"
                        id="dropdown-item-button"
                        type="button"
                        className="dropdown-toggle btn btn-primary"
                    >
                        {rightTitle}
                    </button>
                    <div x-placement="bottom-end" aria-labelledby="dropdown-item-button" className="dropdown-menu dropdown-menu-right" style={menuStyle}>
                        {items}
                    </div>
                </div>
            </div>
        </div>
    );
}
