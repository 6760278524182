import React, { useState, useCallback } from 'react';
import Utils, { useShouldUpdate } from '../common/Utils';
import useApiGetData from '../common/useApiGetData';
import Modal from '../common/Modal';
import DialogAlertView from '../common/DialogAlertView';
import DialogCloseAlert from '../common/DialogCloseAlert';
import WaitIconInline from '../common/WaitIconInline';

export default function AlertList(props) {
    const bId = parseInt(props.bId);
    const allAlert = isNaN(bId) ? 1 : 0;

    const [state, setState] = useState({ alerts: [] });
    const [loading, setLoading] = useState(true);

    const [note, setNote] = useState('');
    const [errMsg, setErrMsg] = useState('');
    const [modalShown, toggleModal] = useState(false);
    const [confirmShown, toggleConfirm] = useState(false);

    const [clickedItem, setClickedItem] = useState(null);

    const cacheKey = 'CacheAlertList' + allAlert + (isNaN(bId) ? '' : 'bId' + bId);

    const shouldUpdate = useShouldUpdate();
    const params = props.skip || isNaN(bId) ? null : { param: { bId } };

    // prettier-ignore
    useApiGetData('alert', cacheKey, setState, data => ({alerts: data.map(e => {
    if (e.createdTime) {
      const d = new Date(e.createdTime);
      e.firstReport = d.toLocaleDateString() + ' ' + d.toLocaleTimeString();
    }
    return e;
  })}), params, setLoading);

    const onAlertClicked = useCallback(
        (e) => {
            const item = Utils.getRowData(e, state.alerts);
            if (item) {
                setClickedItem(item);
                toggleModal((m) => !m);
            }
        },
        [state.alerts]
    );

    const onCloseAlert = useCallback(() => {
        toggleConfirm((m) => !m);
        return true;
    }, []);

    const it = clickedItem
        ? {
              _id: clickedItem._id,
              note: note,
              sn: clickedItem.sn,
              uId: clickedItem.uId,
              bId: clickedItem.bId,
          }
        : null;

    const closeAlert = useCallback(() => {
        const newData = {
            alerts: state.alerts.map((e) => {
                if (e._id === it._id) {
                    e.closeDate = Date.now();
                    const nd = { ts: e.closeDate, note };
                    if (Array.isArray(e.comments)) e.comments.push(nd);
                    else e.comments = [nd];
                }
                return e;
            }),
        };

        if (shouldUpdate.current) {
            setErrMsg('');
            setNote('');
            toggleConfirm(false);
            setState(newData);
        }
        return newData;
    }, [state, it, note, shouldUpdate]);

    const onCloseConfirm = useCallback(() => {
        if (!clickedItem) setErrMsg('Alert not found!');
        else {
            // prettier-ignore
            Utils.apiPostData('close-alert', cacheKey, it, closeAlert,
        (err, errMsg) => {
          setErrMsg(errMsg || 'Error while closing alert');
        }
      );
        }
        return false;
    }, [clickedItem, cacheKey, closeAlert, it]);

    const lbCloseAlert = !Utils.isAdminOrManager() || (clickedItem && clickedItem.closeDate) ? '' : 'Close Alert';

    if (props.skip) return null;

    return loading ? (
        <WaitIconInline />
    ) : (
        <>
            <div className="table-responsive-sm">
                <table className="tab-table table table-sm table-striped table-bordered table-hover">
                    <thead className="thead-dark">
                        <tr>
                            <th scope="col">ID</th>
                            {allAlert ? <th scope="col">Address</th> : null}
                            <th scope="col">Unit #</th>
                            <th scope="col">First reported</th>
                            <th scope="col">Status</th>
                            <th scope="col">Location</th>
                            <th scope="col">Type</th>
                        </tr>
                    </thead>
                    <tbody>
                        {state.alerts.map((e) => (
                            <tr key={e._id} onClick={onAlertClicked} data-row-idx={e._id} className="clickable-row text-danger">
                                <td>{e._id}</td>
                                {allAlert ? <td>{Utils.formatAddress(e)}</td> : null}
                                <td>{e.uName}</td>
                                <td>{e.firstReport}</td>
                                <td>{e.closeDate ? 'Close' : 'Open'}</td>
                                <td>
                                    {e.rName}, {e.appName}, {e.tName}
                                </td>
                                <td>{e.type}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <Modal title="Alert View" show={modalShown} toggle={toggleModal} onOk={onCloseAlert} lbCancel="Return" lbOk={lbCloseAlert}>
                <DialogAlertView alert={clickedItem} />
            </Modal>
            <Modal title="Closing Alert Confirmation" show={confirmShown} toggle={toggleConfirm} lbOk="Yes" lbCancel="No" onOk={() => onCloseConfirm()}>
                <DialogCloseAlert alert={clickedItem} note={note} setNote={setNote} msg={errMsg} setMsg={setErrMsg} />
            </Modal>
        </>
    );
}
