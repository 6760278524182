import useApiGetData from '../common/useApiGetData';
import { AppCache } from './MyCache';

const cacheKey = 'BuildingViewDetail';

export const defaultBuilding = {
    _id: 0,
    street: '',
    city: '',
    state: '',
    managerName: '',
    pic: 'nil',
    alerts: 0,
    sensors: [],
    gallons: 0,
    units: [],
};

export function GetBuildingJson(bd) {
    if (!bd && !bd._id) return defaultBuilding;
    return {
        street: bd.street,
        city: bd.city,
        state: bd.state,
        managerName: bd.mngFirstName + ' ' + bd.mngLastName,
        pic: bd.pic,
        alerts: bd.cAlerts + bd.oAlerts,
        sensors: bd.sensors,
        sensorCnt: bd.sensors.length || 0,
        gallons: bd.gallons || 0,
        units: Array.isArray(bd.units) ? bd.units : [],
    };
}

export function addBuildingToCache(bd) {
    if (bd && bd._id) {
        const b = GetBuildingJson(bd);
        if (b !== defaultBuilding) AppCache.add(cacheKey + bd._id, b);
    }
}

export default function useGetBuildingById(bId, setBuilding, skip = false, fnSetLoading = null) {
    // prettier-ignore
    useApiGetData('building', cacheKey + bId, setBuilding,
    data => { return Array.isArray(data) && data.length ? GetBuildingJson(data[0]) : defaultBuilding; },
    { param: { bId }, skip }, fnSetLoading);
}
