import React from 'react';
import HeaderTopAdmin from '../admin/HeaderTopAdmin';
import Header from '../common/Header';
import Utils from '../common/Utils';

export default function TabCompany(props) {
    let newProps = Utils.cloneObject(props, 'nav', 'menu');
    return (
        <>
            <HeaderTopAdmin />
            <Header menu={'menuAdmin'} activeIdx={1} render={() => <Header nav={true} menu={'menuCompany'} {...newProps} />} />
        </>
    );
}
