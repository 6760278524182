import React, { useCallback, useEffect, useRef } from 'react';

export default function MessageInline(props) {
    const timeoutId = useRef(0);
    const timeoutMs = props.timeout || 3000;
    const updateMsg = props.setMsg || null;

    const clearMsg = useCallback(() => {
        if (updateMsg) updateMsg(null);
    }, [updateMsg]);

    useEffect(() => {
        if (timeoutId.current) {
            if (props.msg) return;
            clearTimeout(timeoutId.current);
            timeoutId.current = 0;
        }
        if (props.msg)
            timeoutId.current = setTimeout(() => {
                if (timeoutId.current) clearMsg();
                timeoutId.current = 0;
            }, timeoutMs);

        return () => {
            if (timeoutId.current) {
                clearTimeout(timeoutId.current);
                clearMsg();
            }
            timeoutId.current = 0;
        };
    }, [clearMsg, timeoutMs, props.msg]);

    const alertType = props.info ? 'alert-success' : 'alert-danger';

    return props.msg ? (
        <div className={'alert ' + alertType} role="alert" style={{ marginTop: '0.5rem', marginBottom: '0.5rem' }}>
            {props.msg}
            <button type="button" className="close" aria-label="Close">
                <span aria-hidden="true" onClick={clearMsg}>
                    &times;
                </span>
            </button>
        </div>
    ) : null;
}
