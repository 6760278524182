import React from 'react';
import waitSpinner from '../img/waiting.svg';

export default function WaitIconInline(props) {
    let cls = 'text-center loadSpinnerInline';
    if (props.hidden || props.hide) cls += ' hidden';
    const paddingTop = isNaN(props.padding) ? '1rem' : props.padding + 'rem';
    return (
        <div className={cls} style={{ paddingTop }}>
            <img src={waitSpinner} alt="Loading..." />
        </div>
    );
}
